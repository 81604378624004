import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const EnvironmentIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 16 16'}
    sx={{ width: '16px', height: '16px' }}
    {...props}>
    <path
      d={
        'M14.1181 4.02076C13.7819 3.24555 13.2972 2.54365 12.6914 1.95469C12.0835 1.3604 11.3669 0.88872 10.5806 0.565402C9.76278 0.226116 8.89492 0.0546875 8.00028 0.0546875C7.10564 0.0546875 6.23778 0.226116 5.41992 0.563616C4.62885 0.890402 3.91992 1.35826 3.30921 1.9529C2.70371 2.5422 2.21906 3.24401 1.88242 4.01897C1.53421 4.82255 1.35742 5.67612 1.35742 6.55469C1.35742 7.8154 1.65921 9.07076 2.25206 10.2815C2.72885 11.2547 3.39492 12.2029 4.23421 13.1047C5.66814 14.644 7.17171 15.585 7.59849 15.8368C7.71964 15.9084 7.8578 15.946 7.99849 15.9458C8.13778 15.9458 8.27528 15.91 8.39849 15.8368C8.82528 15.585 10.3289 14.644 11.7628 13.1047C12.6021 12.2047 13.2681 11.2547 13.7449 10.2815C14.3414 9.07255 14.6431 7.81897 14.6431 6.55647C14.6431 5.6779 14.4664 4.82433 14.1181 4.02076ZM8.00028 14.5761C6.82349 13.8279 2.64314 10.8654 2.64314 6.55647C2.64314 5.1654 3.19849 3.85826 4.20742 2.87254C5.21992 1.88504 6.56635 1.3404 8.00028 1.3404C9.43421 1.3404 10.7806 1.88504 11.7931 2.87433C12.8021 3.85826 13.3574 5.1654 13.3574 6.55647C13.3574 10.8654 9.17706 13.8279 8.00028 14.5761ZM8.00028 3.55469C6.26456 3.55469 4.85742 4.96183 4.85742 6.69755C4.85742 8.43326 6.26456 9.8404 8.00028 9.8404C9.73599 9.8404 11.1431 8.43326 11.1431 6.69755C11.1431 4.96183 9.73599 3.55469 8.00028 3.55469ZM9.41457 8.11183C9.22907 8.29786 9.00862 8.44538 8.7659 8.5459C8.52319 8.64642 8.26299 8.69795 8.00028 8.69755C7.46635 8.69755 6.96456 8.48862 6.58599 8.11183C6.39996 7.92633 6.25245 7.70589 6.15193 7.46317C6.05141 7.22045 5.99987 6.96025 6.00028 6.69755C6.00028 6.16362 6.20921 5.66183 6.58599 5.28326C6.96456 4.90469 7.46635 4.69755 8.00028 4.69755C8.53421 4.69755 9.03599 4.90469 9.41457 5.28326C9.79314 5.66183 10.0003 6.16362 10.0003 6.69755C10.0003 7.23147 9.79314 7.73326 9.41457 8.11183Z'
      }
      fill={'#a9abb2'}
    />
  </SvgIcon>
);
