import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

export const TypographyVezitsa: FC<TypographyProps> = (
  props: TypographyProps
) => {
  const { children, ...other } = props;
  return (
    <Typography
      {...other}
      style={{ fontFamily: ['Vezitsa', 'sans-serif'].join(', ') }}>
      {children}
    </Typography>
  );
};
