import { Button, ButtonProps, Typography } from '@mui/material';
import { ROUTE_AUTHENTICATION, ROUTE_TEMPLES } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  to?: string;
  label: string;
  activeOnlyWhenExact?: boolean;
  onClick?: () => void;
  onBeforeRedirect?: () => void;
  isMobile?: boolean;
}

export const NavigationItemButton: FC<Props> = (props: Props) => {
  const {
    to,
    label,
    activeOnlyWhenExact,
    onClick,
    isMobile,
    onBeforeRedirect,
  } = props;
  const history = useHistory();
  const match = useRouteMatch({
    path: to?.toString(),
    exact: activeOnlyWhenExact,
  });
  const isMainPage = useRouteMatch({
    path: [ROUTE_TEMPLES, ROUTE_AUTHENTICATION],
    exact: true,
  });
  const handleClick = (): void => {
    if (onClick) {
      onClick();
    } else if (to) {
      if (onBeforeRedirect) {
        onBeforeRedirect();
      }
      history.push(to);
    }
  };
  return (
    <Button
      variant={'outlined'}
      color={'inherit'}
      fullWidth={isMobile}
      sx={(theme: Theme) => ({
        padding: theme.spacing(1.25, 3),
        color:
          (match || isMainPage) && !isMobile ? 'white' : theme.colors.textColor,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: isMobile ? '50px' : '6px',
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(1.25),
        },
      })}
      onClick={handleClick}>
      <Typography
        variant={'h4'}
        fontSize={'14px'}
        lineHeight={'14px'}
        fontWeight={'normal'}>
        {label}
      </Typography>
    </Button>
  );
};
