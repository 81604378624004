import { Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import Vesitsa from '@assets/fonts/Vezitsa.ttf';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label1: true;
    label2: true;
    body3: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    dense: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      primary: string;
      primaryDark: string;
      primaryLight: string;
      success: string;
      textColor: string;
      iconColor: string;
      hoverColor: string;
      borderColor: string;
      secondaryBorderColor: string;
      inputBorder: string;
      tableBorder: string;
      grayText: string;
      darkGrayText: string;
      grayBackground: string;
      secondaryGrayBackground: string;
      alert: string;
      redBackground: string;
      errorBorder: string;
      orange: string;
      orangeLight: string;
      tooltip: string;
      red: string;
      lightBlueColor: string;
      green: string;
      greenBackground: string;
      buttonGrayHover: string;
      white: string;
      attachFileBackground: string;
      headerGray: string;
    };
  }

  interface ThemeOptions {
    colors?: {
      primary?: string;
      primaryDark?: string;
      primaryLight?: string;
      success?: string;
      textColor?: string;
      iconColor?: string;
      hoverColor?: string;
      borderColor?: string;
      secondaryBorderColor?: string;
      inputBorder?: string;
      tableBorder?: string;
      grayText?: string;
      darkGrayText?: string;
      grayBackground?: string;
      secondaryGrayBackground?: string;
      alert?: string;
      redBackground?: string;
      errorBorder?: string;
      orange?: string;
      orangeLight?: string;
      tooltip?: string;
      red?: string;
      lightBlueColor?: string;
      green?: string;
      greenBackground?: string;
      buttonGrayHover?: string;
      white?: string;
      blue?: string;
      attachFileBackground?: string;
      headerGray: string;
    };
  }
}

const options: ThemeOptions = {
  colors: {
    primary: '#ffc737',
    primaryDark: 'rgb(178, 139, 38)',
    primaryLight: 'rgba(255, 199, 55, 0.04)',
    success: '#2bc268',
    textColor: '#1c2232',
    iconColor: 'rgba(28, 34, 50, 0.5)',
    hoverColor: 'rgba(28, 34, 50, 0.7)',
    borderColor: '#dededb',
    secondaryBorderColor: '#dfdde4',
    tableBorder: '#e2e1e5',
    inputBorder: '#e2e1e6',
    grayText: 'rgba(28, 34, 50, 0.5)',
    darkGrayText: '#5e5e5e',
    grayBackground: '#f3f2f7',
    secondaryGrayBackground: '#f7f8fa',
    alert: '#f7ebcd',
    redBackground: '#fceff0',
    errorBorder: '#ff7b7b',
    orange: '#eb8b46',
    orangeLight: '#f2aa75',
    tooltip: '#fff5d1',
    red: '#de606c',
    lightBlueColor: '#abc2ff',
    buttonGrayHover: 'rgba(28, 34, 50, 0.04)',
    white: '#ffffff',
    attachFileBackground: '#eeedf2',
    headerGray: '#888a95',
    blue: '#3e9ded',
    green: '#4fad2d',
  },
};

const theme: Theme = createTheme(options);

export default createTheme({
  ...theme,
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.grayBackground,
    },
    text: {
      primary: theme.colors.textColor,
    },
    error: {
      main: theme.colors.errorBorder,
    },
    success: {
      main: theme.colors.success,
    },
  },
  typography: {
    fontFamily: ['Rubik', 'sans-serif'].join(', '),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Vezitsa',
          src: `url(${Vesitsa}) format("truetype")`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '32px',
        },
        h2: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '32px',
        },
        h4: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
        },
        h5: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
        },
        h6: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '16px',
        },
        body1: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '24px',
        },
        body2: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
      variants: [
        {
          props: { variant: 'body3' },
          style: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
          },
        },
        {
          props: { variant: 'label1' },
          style: {
            fontSize: '12px',
            lineHeight: '16px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
          },
        },
        {
          props: { variant: 'label2' },
          style: {
            fontFamily: 'Rubik',
            fontSize: '10px',
            lineHeight: '16px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            letterSpacing: '0.03em',
            textTransform: 'uppercase',
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '6px',
          paddingTop: '12px',
          paddingBottom: '12px',
          textTransform: 'none',
          fontSize: '16px',
          lineHeight: '24px',
        },
        outlined: {
          paddingTop: '11px',
          paddingBottom: '11px',
        },
        sizeSmall: {
          fontSize: '14px',
          lineHeight: '20px',
          paddingTop: '10px',
          paddingBottom: '10px',
        },
        outlinedSizeSmall: {
          paddingTop: '9px',
          paddingBottom: '9px',
        },
        outlinedSecondary: {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
          ':hover': {
            background: theme.colors.buttonGrayHover,
          },
        },
        containedPrimary: {
          ':disabled': {
            background: theme.colors.primary,
            opacity: 0.5,
            color: '#ffffff',
          },
        },
        textPrimary: {
          ':hover': {
            color: theme.colors.primaryDark,
            background: 'transparent',
          },
        },
      },
      variants: [
        {
          props: { color: 'error' },
          style: {
            color: theme.colors.red,
            borderColor: theme.colors.red,
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: theme.colors.textColor,
            borderColor: theme.colors.borderColor,
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { size: 'dense' },
          style: {
            padding: '8px',
            margin: '-8px',
            '&:hover': {
              color: theme.colors.hoverColor,
              background: 'none',
            },
          },
        },
      ],
      styleOverrides: {
        colorPrimary: {
          color: theme.colors.primary,
        },
        root: {
          color: theme.colors.iconColor,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.colors.borderColor,
          '&::before': {
            borderColor: theme.colors.borderColor,
          },
          '&::after': {
            borderColor: theme.colors.borderColor,
          },
        },
        wrapper: {
          color: theme.colors.grayText,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
        textAlignLeft: {
          '&::before': {
            width: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid ' + theme.colors.tableBorder,
        },
        body: {
          padding: theme.spacing(2.75, 5, 2.75, 0),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: theme.spacing(1),
          backgroundColor: theme.colors.tooltip,
          color: theme.colors.textColor,
          borderRadius: '6px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          maxWidth: '268px',
        },
        arrow: {
          color: theme.colors.tooltip,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: theme.colors.grayText,
            opacity: 1,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '24px',
          borderRadius: '6px',
          backgroundColor: 'white',
          '& .Mui-disabled': {
            backgroundColor: theme.colors.grayBackground,
            borderColor: theme.colors.inputBorder,
          },
        },
        input: {
          height: '24px',
          padding: '12px 8px',
          '&[type=number]': {
            '-moz-appearance': 'textfield',
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
        sizeSmall: {
          fontSize: '14px',
          lineHeight: '20px',
        },
        inputSizeSmall: {
          height: '20px',
          padding: '10px 8px',
        },
        notchedOutline: {
          borderColor: theme.colors.inputBorder,
        },
        multiline: {
          padding: '0px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(4),
          [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2.5),
            paddingBottom: theme.spacing(3),
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: theme.spacing(1.25),
          lineHeight: theme.spacing(2),
          margin: '3px 0 0 0',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(0, -22px)',
          textTransform: 'uppercase',
          color: theme.colors.grayText,
          fontSize: theme.spacing(1.5),
          lineHeight: theme.spacing(2),
          letterSpacing: '0.05em',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          position: 'absolute',
          whiteSpace: 'nowrap',
          transform: 'translate(0, -22px)',
          textTransform: 'uppercase',
          color: theme.colors.grayText,
          fontSize: theme.spacing(1.5),
          lineHeight: theme.spacing(2),
          letterSpacing: '0.05em',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            padding: '0px',
            '.MuiAutocomplete-input': {
              padding: '12px 8px',
            },
            '.MuiAutocomplete-clearIndicator': {
              visibility: 'visible',
              marginRight: '2px',
            },
            '.MuiAutocomplete-endAdornment': {
              right: 0,
              top: 'inherit',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: theme.spacing(75),
          boxShadow: '0px 4px 34px rgba(121, 151, 230, 0.5)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          minWidth: '500px',
          maxWidth: '1300px',
        },
        paperFullScreen: {
          borderRadius: 'unset',
          minWidth: '0',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0),
        },
      },
    },
  },
});
