import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  src?: string;
  alt?: string;
  shadow?: number;
}

export const MainImageContainer: FC<Props> = (props: Props) => {
  const { src, alt, shadow } = props;
  return (
    <Box position={'relative'} width={'100%'} height={'100%'}>
      {(shadow || 0) > 0 && (
        <Box
          position={'absolute'}
          top={0}
          right={0}
          bottom={0}
          left={0}
          sx={(theme: Theme) => ({
            background: theme.colors.textColor,
            opacity: shadow,
          })}
        />
      )}
      <img
        src={src}
        alt={alt}
        width={'100%'}
        height={'100%'}
        style={{ display: 'block', objectFit: 'cover' }}
      />
    </Box>
  );
};
