import { ComponentVariant } from '@/types/ComponentVariant';
import { Grid, MenuItem } from '@mui/material';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { TempleDTO } from '@/types/TempleDTO';
import { TypographyLink } from '@/components/common/TypographyLink';
import {
  clearTemple,
  getCurrentRegion,
  getCurrentTemple,
  setCurrentRegion,
  setCurrentTemple,
  setDefaultPageValues,
} from '@/services/templesSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import React, { FC } from 'react';

interface Props {
  regions?: RegionDTO[];
  isFetching?: boolean;
  variant?: ComponentVariant;
  isMobile?: boolean;
}

export const RegionsList: FC<Props> = (props: Props) => {
  const { regions, isFetching, variant, isMobile } = props;
  const dispatch = useAppDispatch();
  const currentRegion = useAppSelector(getCurrentRegion);
  const currentTemple = useAppSelector(getCurrentTemple);

  const setRegion = (value: RegionDTO | null) => {
    dispatch(clearTemple());
    dispatch(setCurrentRegion(value));
    dispatch(setDefaultPageValues());
  };

  const setTemple = (value?: TempleDTO | null) => {
    dispatch(setCurrentTemple(value ? value : null));
    dispatch(setDefaultPageValues());
  };

  return (
    <Grid container={true} direction={'row'} spacing={isMobile ? 2 : 3}>
      <Grid item={true} md={5} xs={12}>
        <RegionSelectField
          onChange={(value: RegionDTO | null) => {
            setRegion(value);
          }}
          regions={regions}
          region={currentRegion}
          disabled={isFetching}
          variant={variant}
        />
      </Grid>
      <Grid item={true} md={7} xs={12}>
        <OutlinedSelect
          displayEmpty={true}
          label={'Церковь'}
          placeholder={'Выберите церковь'}
          withLabel={true}
          disabled={!currentRegion}
          value={currentTemple?.id}
          backgroundVariant={variant}
          onChange={(event) => {
            const selectTemple = currentRegion?.temples.find(
              (temple) => temple.id === (event.target.value as number)
            );
            setTemple(selectTemple);
          }}>
          {currentRegion?.temples &&
            currentRegion.temples.map((value) => (
              <MenuItem
                key={value.id}
                value={value.id}
                style={{ whiteSpace: 'normal', maxWidth: '100%' }}>
                {value.name}
              </MenuItem>
            ))}
        </OutlinedSelect>
      </Grid>
    </Grid>
  );
};
