import { Button, ButtonProps, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  to: string;
  label: string;
  size?: 'small' | 'medium';
  activeOnlyWhenExact?: boolean;
  onBeforeRedirect?: () => void;
  isRedirect?: boolean;
}

export const MenuLink: FC<Props> = (props: Props) => {
  const {
    label,
    to,
    activeOnlyWhenExact,
    onBeforeRedirect,
    isRedirect,
    ...other
  } = props;
  const history = useHistory();
  const match = useRouteMatch({
    path: to.toString(),
    exact: activeOnlyWhenExact,
  });
  const handleClick = (): void => {
    if (isRedirect) {
      location.href = to;
    } else {
      if (onBeforeRedirect) {
        onBeforeRedirect();
      }
      history.push(to);
    }
  };
  return (
    <Button
      color={'inherit'}
      fullWidth={true}
      sx={{
        textTransform: 'none',
        padding: (theme: Theme) => {
          if (props.size === 'small') {
            return theme.spacing(0.75, 0.875);
          }
          return theme.spacing(1.25, 1);
        },
        backgroundColor: (theme: Theme) =>
          match ? theme.colors.alert + '!important' : 'inherit',
      }}
      onClick={handleClick}
      {...other}>
      <Grid
        container={true}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'baseline'}
        spacing={1}>
        <Grid item={true}>
          <Typography
            whiteSpace={'pre-line'}
            sx={{
              fontSize: (theme: Theme) => {
                if (props.size === 'small') {
                  return theme.spacing(1.75);
                }
                return theme.spacing(2);
              },
              color: (theme: Theme) =>
                match ? theme.colors.textColor : theme.colors.headerGray,
              fontWeight: 400,
              lineHeight: (theme: Theme) => theme.spacing(2.5),
            }}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};
