import { Box, Grid, Tabs, useMediaQuery } from '@mui/material';
import { FilledLogoIcon } from '@/app/icons/FilledLogoIcon';
import { Logo } from '@/app/icons/Logo';
import { NavigationItemButton } from '@/components/common/navigation/NavigationItemButton';
import { NavigationTab } from '@/components/common/navigation/NavigationTab';
import { ROLE_ADMIN, ROLE_DEACON } from '@/app/roles';
import {
  ROUTE_ABOUT,
  ROUTE_ADMIN,
  ROUTE_AUTHENTICATION,
  ROUTE_DONATIONS,
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE,
  ROUTE_TEMPLES,
} from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { geMainRouteFromPath } from '@/utils/string-utils';
import { getCurrentUser, signOut } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC, SyntheticEvent } from 'react';

interface Props {
  isMainPage: boolean;
}

export const NavigationContainer: FC<Props> = (props: Props) => {
  const { isMainPage } = props;
  const isPad: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleClickLogout = (): void => {
    history.push(ROUTE_HOME);
    dispatch(signOut());
  };

  const handleChange = (event: SyntheticEvent, pathTo: string) => {
    history.push(pathTo);
  };

  return (
    <Grid
      id={'top'}
      container={true}
      justifyContent={'space-between'}
      alignItems={'center'}
      wrap={'nowrap'}>
      <Grid
        item={true}
        display={'flex'}
        zIndex={10}
        pb={1}
        mr={{ md: isMainPage ? 17 : 0, xs: isMainPage ? 12 : 0 }}>
        {isMainPage ? (
          <Box position={'relative'} mt={{ md: '-43px', xs: '-45px' }}>
            <Box position={'absolute'} top={0} left={0}>
              <RouteLink to={ROUTE_TEMPLES}>
                <FilledLogoIcon
                  sx={(theme: Theme) => ({
                    display: 'block',
                    width: theme.spacing(14),
                    height: theme.spacing(14.5),
                    [theme.breakpoints.down('md')]: {
                      width: theme.spacing(12),
                      height: theme.spacing(13),
                    },
                  })}
                />
              </RouteLink>
            </Box>
          </Box>
        ) : (
          <RouteLink to={ROUTE_TEMPLES}>
            <Logo
              style={{ color: 'black' }}
              sx={{
                width: (theme: Theme) => theme.spacing(11.875),
                height: (theme: Theme) => theme.spacing(10.5),
              }}
            />
          </RouteLink>
        )}
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          alignItems={'center'}
          spacing={{ md: 7, sm: 2 }}
          wrap={'nowrap'}>
          <Grid item={true} mb={-0.25}>
            <Tabs
              value={geMainRouteFromPath(history.location.pathname)}
              onChange={handleChange}
              variant={isPad ? 'scrollable' : 'standard'}
              scrollButtons={'auto'}
              sx={(theme: Theme) => ({
                '& .MuiTabs-indicator': {
                  height: '2px',
                },
                '& .MuiTabScrollButton-root': {
                  color: isMainPage ? 'white' : 'black',
                },
                [theme.breakpoints.down('md')]: {
                  maxWidth: '400px',
                },
                [theme.breakpoints.up('md')]: {
                  overflow: 'visible !important',
                  '& .MuiTabs-scroller': {
                    overflow: 'visible !important',
                  },
                },
              })}>
              <NavigationTab
                label={'Главная'}
                value={ROUTE_TEMPLES}
                tabIndex={0}
                secondary={isMainPage}
              />
              <NavigationTab
                label={'Пожертвования'}
                value={ROUTE_DONATIONS}
                tabIndex={1}
                secondary={isMainPage}
              />
              <NavigationTab
                label={'О портале'}
                value={ROUTE_ABOUT}
                tabIndex={2}
                secondary={isMainPage}
              />
              <NavigationTab
                label={'Помощь'}
                value={ROUTE_HELP}
                tabIndex={3}
                secondary={isMainPage}
              />
              {authUser && authUser.roles.indexOf(ROLE_DEACON) > -1 && (
                <NavigationTab
                  value={ROUTE_PROFILE}
                  label={'Профиль'}
                  tabIndex={4}
                  secondary={isMainPage}
                />
              )}
              {authUser && authUser.roles.indexOf(ROLE_DEACON) > -1 && (
                <NavigationTab
                  value={ROUTE_PAYMENTS}
                  label={'Реестр платежей'}
                  tabIndex={5}
                  secondary={isMainPage}
                />
              )}
              {authUser && authUser.roles.indexOf(ROLE_ADMIN) > -1 && (
                <NavigationTab
                  value={ROUTE_ADMIN}
                  label={'Администрирование'}
                  tabIndex={6}
                  secondary={isMainPage}
                />
              )}
            </Tabs>
          </Grid>
          <Grid item={true}>
            {authUser ? (
              <NavigationItemButton
                label={'Выйти'}
                onClick={handleClickLogout}
              />
            ) : (
              <NavigationItemButton
                to={ROUTE_AUTHENTICATION}
                label={'Войти / Подключиться к сервису'}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
