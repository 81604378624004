import { DeaconEditDialog } from '@/components/modal/DeaconEditDialog';
import { EditTempleDTO } from '@/types/EditTempleDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { InfoAlert } from '@/components/common/banner/InfoAlert';
import { TempleForm } from '@/components/common/TempleForm';
import { TempleFormPaper } from '@/components/common/TempleFormPaper';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { UserDTO } from '@/types/user/UserDTO';
import { fillDtoFromEditForm } from '@/utils/request-utils';
import { getCurrentEditTempleForm } from '@/services/templesSlice';
import { getCurrentUser } from '@/services/authSlice';
import {
  templesApiSlice,
  useCreateEditRequestMutation,
  useGetTempleByDeaconQuery,
} from '@/services/api/templesApiSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetEditRequestQuery } from '@/services/api/requestApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useState } from 'react';

export const ProfilePage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const { enqueueSnackbar } = useSnackbar();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const {
    data: temple,
    isLoading: isTempleLoading,
    isFetching: isTempleFetching,
  } = useGetTempleByDeaconQuery(authUser?.templeId || 0, {
    skip: !authUser?.templeId,
  });

  const { refetch: refetchTemple } =
    templesApiSlice.endpoints.getTempleByDeacon.useQuerySubscription(
      authUser?.templeId || 0
    );

  const { data: request } = useGetEditRequestQuery(authUser?.templeId, {
    skip: !temple?.edited,
  });

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [createEditRequest, { isLoading: isLoading }] =
    useCreateEditRequestMutation();

  const [editValue, setEditValues] = useState<{
    request: EditTempleDTO;
    images: File[];
  } | null>(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditValues(null);
    refetchTemple();
  };

  const handleSubmitForm = async (values: TempleFormValues): Promise<void> => {
    const dto: EditTempleDTO = fillDtoFromEditForm(values, temple?.id);
    setEditValues({ request: dto, images: values.attachFiles });
    await setOpenDialog(true);
  };

  const handleConfirmSubmit = async (
    values: {
      request: EditTempleDTO;
      images: File[];
    } | null
  ): Promise<void> => {
    if (!values) {
      enqueueSnackbar('Ошибка считывания полей', {
        variant: 'error',
      });
      return;
    }
    await createEditRequest(values)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Заявка отправлена!', {
          variant: 'success',
        });
        handleCloseDialog();
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      });
  };
  const initialEditValues = useAppSelector(getCurrentEditTempleForm);
  return (
    <Fragment>
      <Grid container={true} direction={'column'} mt={'20px'} wrap={'nowrap'}>
        <Grid item={true} pb={temple?.edited ? 3 : 5}>
          <TypographyVezitsa variant={'h1'} lineHeight={'30px'}>
            {'Редактирование карточки церкви'}
          </TypographyVezitsa>
        </Grid>
        {temple?.edited && (
          <Grid item={true} pb={2}>
            <InfoAlert>
              <Typography variant={'body1'} component={'div'}>
                {'Внесенные изменения находятся на согласовании администрации.'}
              </Typography>
            </InfoAlert>
          </Grid>
        )}
        <Grid item={true}>
          <TempleFormPaper>
            <TempleForm
              initialFormValues={initialEditValues}
              authUser={temple?.edited ? request?.deacon : authUser}
              temple={temple?.edited ? request : temple}
              loadingData={isTempleFetching || isTempleLoading}
              sendingData={isLoading}
              handleSubmit={handleSubmitForm}
              isDisabled={temple?.edited}
              submitButtonText={'Сохранить'}
              isMobile={isMobile}
            />
          </TempleFormPaper>
        </Grid>
      </Grid>
      <DeaconEditDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={() => handleConfirmSubmit(editValue)}
        isMobile={isMobile}
      />
    </Fragment>
  );
};
