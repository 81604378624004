import { DonationDTO } from '@/types/DonationDTO';
import { Grid, Paper, Theme, Typography } from '@mui/material';
import { formatCurrency, formatDonationName } from '@/utils/string-utils';
import React, { FC } from 'react';
import moment from 'moment';

interface Props {
  donationsList: DonationDTO[] | undefined;
}

export const DonationMobileTableContainer: FC<Props> = (props: Props) => {
  const { donationsList } = props;
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'} rowSpacing={1}>
      {donationsList?.map((donation, index) => (
        <Grid item={true} key={index}>
          <Paper elevation={0} sx={{ padding: '12px 16px' }}>
            <Grid container={true} direction={'column'} wrap={'nowrap'}>
              <Grid
                item={true}
                style={{ maxWidth: '80%' }}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                mb={0.5}>
                <Typography
                  variant={'body1'}
                  fontSize={'14px'}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {donation.isAnonymous || !donation.name
                    ? 'Анонимно'
                    : formatDonationName(donation.name)}
                </Typography>
              </Grid>
              <Grid item={true} style={{ maxWidth: '66%' }} mb={0.5}>
                <Typography
                  variant={'body1'}
                  fontSize={'14px'}
                  color={(theme: Theme) => theme.colors.grayText}>
                  {donation.templeName}
                </Typography>
              </Grid>
              <Grid item={true} mb={1.5}>
                <Typography
                  variant={'body1'}
                  fontSize={'14px'}
                  fontWeight={500}>
                  {formatCurrency(donation.amount, 0)}
                </Typography>
              </Grid>
              <Grid item={true} mb={1.5} style={{ maxWidth: '100%' }}>
                <Typography
                  variant={'body1'}
                  fontSize={'14px'}
                  sx={{ maxWidth: '100%', wordBreak: 'break-all' }}
                  color={(theme: Theme) => theme.colors.grayText}>
                  {donation.comment}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography
                  variant={'body1'}
                  fontSize={'14px'}
                  color={(theme: Theme) => theme.colors.grayText}>
                  {moment(donation.date).format('DD.MM.YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
