import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SearchIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 22 22'}
    sx={{ width: '22px', height: '22px' }}
    {...props}>
    <path
      d={
        'M8.8125 17.418C10.6289 17.418 12.3164 16.8438 13.7109 15.8711L18.832 21.0039C19.1016 21.2617 19.4531 21.3906 19.8281 21.3906C20.6133 21.3906 21.1758 20.793 21.1758 20.0195C21.1758 19.6562 21.0586 19.3047 20.7891 19.0352L15.7031 13.9375C16.7695 12.5078 17.4023 10.7383 17.4023 8.82812C17.4023 4.10547 13.5352 0.238281 8.8125 0.238281C4.08984 0.238281 0.222656 4.09375 0.222656 8.82812C0.222656 13.5508 4.07812 17.418 8.8125 17.418ZM8.8125 15.4141C5.19141 15.4141 2.21484 12.4375 2.21484 8.82812C2.21484 5.21875 5.19141 2.24219 8.8125 2.24219C12.4219 2.24219 15.3984 5.21875 15.3984 8.82812C15.3984 12.4375 12.4219 15.4141 8.8125 15.4141Z'
      }
      fill={'#ffc737'}
    />
  </SvgIcon>
);
