import { AppBar, Box, Collapse, Drawer, Grid, IconButton } from '@mui/material';
import { BurgerIcon } from '@/app/icons/BurgerIcon';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { Logo } from '@/app/icons/Logo';
import { MobileMenuButton } from '@/components/common/MobileMenuButton';
import { MobileMenuLink } from '@/components/common/MobileMenuLink';
import { NavigationItemButton } from '@/components/common/navigation/NavigationItemButton';
import { ROLE_ADMIN, ROLE_DEACON } from '@/app/roles';
import {
  ROUTE_ABOUT,
  ROUTE_ADMIN,
  ROUTE_AUTHENTICATION,
  ROUTE_DONATIONS,
  ROUTE_HELP,
  ROUTE_HELP_CONNECT,
  ROUTE_HELP_FAQ,
  ROUTE_HELP_FEEDBACK,
  ROUTE_HELP_WORK,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_PROFILE,
  ROUTE_TEMPLES,
} from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { VezitsaArrowIcon } from '@/app/icons/VezitsaArrowIcon';
import { getCurrentUser, signOut } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { FC, Fragment, useEffect, useState } from 'react';

export const NavigationDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const [open, setOpen] = useState<boolean>(false);
  const handleBeforeRedirect = (): void => {
    setOpen(false);
  };
  const handleClickLogout = (): void => {
    history.push(ROUTE_HOME);
    dispatch(signOut());
    setOpen(false);
  };
  const isHelpPage = useRouteMatch({
    path: ROUTE_HELP,
    exact: false,
  });
  const [extend, setExtend] = useState<boolean>(Boolean(isHelpPage));
  useEffect(() => {
    if (!isHelpPage) {
      setExtend(Boolean(isHelpPage));
    }
  }, [isHelpPage, open]);
  return (
    <Fragment>
      <IconButton
        style={{ color: 'inherit' }}
        onClick={() => {
          setOpen(true);
        }}>
        <BurgerIcon style={{ display: 'block' }} />
      </IconButton>
      <Drawer
        open={open}
        anchor={'right'}
        PaperProps={{ sx: { width: '100%' } }}>
        <Box p={(theme: Theme) => theme.spacing(2.5, 3, 3, 2)} height={'100%'}>
          <Grid
            container={true}
            direction={'column'}
            sx={{ height: '100%' }}
            wrap={'nowrap'}>
            <Grid item={true} pb={(theme: Theme) => theme.spacing(2)}>
              <AppBar
                position={'absolute'}
                sx={(theme: Theme) => ({
                  backgroundColor: 'white',
                  boxShadow: 'none',
                  borderBottom: '1px solid ' + theme.colors.borderColor,
                })}>
                <Box sx={{ width: '100%', padding: '0 16px' }}>
                  <Grid
                    container={true}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Grid item={true} display={'flex'} pb={'5px'}>
                      <Logo
                        sx={{
                          width: (theme: Theme) => theme.spacing(7.875),
                          height: (theme: Theme) => theme.spacing(7.375),
                        }}
                      />
                    </Grid>
                    <Grid item={true}>
                      <IconButton
                        onClick={() => {
                          setOpen(false);
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </AppBar>
            </Grid>
            <Grid item={true} xs={true}>
              <Grid
                container={true}
                direction={'column'}
                spacing={1}
                sx={{ height: '100%' }}
                justifyContent={'center'}
                alignItems={'center'}
                wrap={'nowrap'}>
                <Grid item={true}>
                  <MobileMenuLink
                    label={'Главная'}
                    to={ROUTE_TEMPLES}
                    onBeforeRedirect={handleBeforeRedirect}
                    activeOnlyWhenExact={true}
                  />
                </Grid>
                <Grid item={true}>
                  <MobileMenuLink
                    label={'Пожертвования'}
                    to={ROUTE_DONATIONS}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MobileMenuLink
                    label={'О портале'}
                    to={ROUTE_ABOUT}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                {authUser && authUser.roles.indexOf(ROLE_DEACON) > -1 && (
                  <Fragment>
                    <Grid item={true}>
                      <MobileMenuLink
                        label={'Профиль'}
                        to={ROUTE_PROFILE}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MobileMenuLink
                        label={'Реестр платежей'}
                        to={ROUTE_PAYMENTS}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                  </Fragment>
                )}
                {authUser && authUser.roles.indexOf(ROLE_ADMIN) > -1 && (
                  <Grid item={true}>
                    <MobileMenuLink
                      label={'Администрирование'}
                      to={ROUTE_ADMIN}
                      onBeforeRedirect={handleBeforeRedirect}
                    />
                  </Grid>
                )}
                <Grid item={true}>
                  <Grid container={true} direction={'column'} wrap={'nowrap'}>
                    <Grid item={true}>
                      <MobileMenuButton
                        sx={(theme: Theme) => ({
                          color: isHelpPage
                            ? theme.colors.textColor
                            : theme.colors.grayText,
                        })}>
                        <Grid
                          container={true}
                          justifyContent={'center'}
                          onClick={() => setExtend(!extend)}>
                          <Grid item={true} pr={1.5}>
                            {'Помощь'}
                          </Grid>
                          <Grid item={true}>
                            <VezitsaArrowIcon
                              display={'block'}
                              style={{
                                transform: !extend ? 'rotate(180deg)' : '',
                                transition: 'transform 0.3s',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </MobileMenuButton>
                    </Grid>
                    <Collapse in={extend}>
                      <Grid item={true} pt={4}>
                        <Grid
                          container={true}
                          direction={'column'}
                          wrap={'nowrap'}>
                          <Grid item={true}>
                            <MobileMenuLink
                              label={'Как работает сервис'}
                              to={ROUTE_HELP_WORK}
                              onBeforeRedirect={handleBeforeRedirect}
                              size={'small'}
                            />
                          </Grid>
                          <Grid item={true}>
                            <MobileMenuLink
                              label={'Часто задаваемые вопросы'}
                              to={ROUTE_HELP_FAQ}
                              onBeforeRedirect={handleBeforeRedirect}
                              size={'small'}
                            />
                          </Grid>
                          <Grid item={true}>
                            <MobileMenuLink
                              label={'Подключение к сервису'}
                              to={ROUTE_HELP_CONNECT}
                              onBeforeRedirect={handleBeforeRedirect}
                              size={'small'}
                            />
                          </Grid>
                          <Grid item={true}>
                            <MobileMenuLink
                              label={'Обратная связь'}
                              to={ROUTE_HELP_FEEDBACK}
                              onBeforeRedirect={handleBeforeRedirect}
                              size={'small'}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              {authUser ? (
                <NavigationItemButton
                  label={'Выйти'}
                  onClick={handleClickLogout}
                  isMobile={true}
                />
              ) : (
                <NavigationItemButton
                  to={ROUTE_AUTHENTICATION}
                  label={'Войти / Подключиться к сервису'}
                  onBeforeRedirect={handleBeforeRedirect}
                  isMobile={true}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Fragment>
  );
};
