import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { MESSAGE_UNKNOWN } from '@/utils/validation-utils';
import { ROUTE_REGISTRATION_SUCCESS } from '@/app/routes';
import { RegistrationRequestDTO } from '@/types/RegistrationRequestDTO';
import { TempleForm } from '@/components/common/TempleForm';
import { TempleFormPaper } from '@/components/common/TempleFormPaper';
import { TempleFormValues } from '@/types/form/TempleFormValues';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import {
  clearCreateTempleForm,
  getCurrentCreateTempleForm,
  setCreateTempleForm,
} from '@/services/templesSlice';
import { convertFileToPromiseAttach } from '@/utils/file-utils';
import { fillDtoFromRegistrationForm } from '@/utils/request-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCreateRegistrationRequestMutation } from '@/services/api/requestApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

export const RegistrationPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [createRequest, { isLoading: isLoading }] =
    useCreateRegistrationRequestMutation();
  const handleSubmit = async (values: TempleFormValues): Promise<void> => {
    Promise.all(convertFileToPromiseAttach(values.attachFiles)).then(
      (attachFiles) => {
        dispatch(setCreateTempleForm({ ...values, attachFiles: attachFiles }));
      }
    );
    const requestDto: RegistrationRequestDTO =
      fillDtoFromRegistrationForm(values);
    await createRequest({ request: requestDto, images: values.attachFiles })
      .unwrap()
      .then(() => {
        dispatch(clearCreateTempleForm());
        history.push(ROUTE_REGISTRATION_SUCCESS);
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data === null ? MESSAGE_UNKNOWN : e.data.message, {
          variant: 'error',
        });
      });
  };

  const initialCreateValues = useAppSelector(getCurrentCreateTempleForm);
  return (
    <Grid container={true} direction={'column'} mt={'20px'}>
      <Grid item={true}>
        <TypographyVezitsa variant={'h1'} lineHeight={'30px'}>
          {'Подключение церкви к сервису пожертвований'}
        </TypographyVezitsa>
      </Grid>
      <Grid item={true} mt={'11px'} pb={isMobile ? 4 : 7}>
        <Typography variant={'h5'} fontWeight={400}>
          {'Для создания заявки на подключение необходимо заполнить все поля'}
        </Typography>
      </Grid>
      <Grid item={true} style={{ maxWidth: '100%' }}>
        <TempleFormPaper>
          <TempleForm
            initialFormValues={initialCreateValues}
            handleSubmit={handleSubmit}
            sendingData={isLoading}
            submitButtonText={'Зарегистрироваться'}
            isMainRegistrationPage={true}
            isMobile={isMobile}
          />
        </TempleFormPaper>
      </Grid>
    </Grid>
  );
};
