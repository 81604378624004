import * as Yup from 'yup';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { CreateDonationDTO } from '@/types/CreateDonationDTO';
import { CreateDonationResponseDTO } from '@/types/CreateDonationResponseDTO';
import { DonationButtonsGroup } from '@/components/donations/card/DonationButtonsGroup';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Formik, FormikProps } from 'formik';
import { GET_OFFER_URL } from '@/app/config';
import { InvoiceBoxIcon } from '@/app/icons/payment/InvoiceBoxIcon';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { TempleViewDTO } from '@/types/TempleViewDTO';
import { Theme } from '@mui/material/styles';
import { TypographyLink } from '@/components/common/TypographyLink';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import { donationSchema } from '@/validation/donationSchema';
import { emailSchema } from '@/validation/emailSchema';
import { fullNameSchema } from '@/validation/fullNameSchema';
import { getMessageMaxLetterSize } from '@/utils/validation-utils';
import { getPaymentTemplate } from '@/app/routes';
import { templeSchema } from '@/validation/templeSchema';
import { useCreateDonationMutation } from '@/services/api/donationsApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';

interface Props {
  regions?: RegionDTO[];
  isFetching?: boolean;
  fromTemplePage?: boolean;
  temple?: TempleViewDTO;
  isMobile?: boolean;
}

interface FormValues {
  temple: TempleViewDTO | null;
  donationValue: number;
  fullName: string;
  email: string;
  comment: string;
  isAnonymous: boolean;
}

const validationSchema = Yup.object({
  temple: templeSchema,
  donationValue: donationSchema,
  fullName: fullNameSchema,
  email: emailSchema,
  comment: Yup.string().max(250, getMessageMaxLetterSize(250)).notRequired(),
});

export const DonationCard: FC<Props> = (props: Props) => {
  const { regions, isFetching, fromTemplePage, temple, isMobile } = props;
  const [region, setRegion] = useState<RegionDTO | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [createDonation, { isLoading: isDonationLoading }] =
    useCreateDonationMutation();
  const initialValues: FormValues = {
    temple: temple || null,
    donationValue: 100,
    fullName: '',
    email: '',
    comment: '',
    isAnonymous: false,
  };
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('donationAction');
  }, [executeRecaptcha]);

  const handleSubmit = async (values: FormValues) => {
    handleReCaptchaVerify().then(async (token) => {
      const windowReference = window.location;
      const dto: CreateDonationDTO = {
        templeId: fromTemplePage ? temple?.id : values.temple?.id,
        fullName: values.fullName.trim().replace(/\s+/g, ' '),
        email: values.email.trim(),
        comment: values.comment.trim(),
        amount: values.donationValue,
        isAnonymous: values.isAnonymous,
        token: token,
      };
      console.log(dto);
      await createDonation(dto)
        .unwrap()
        .then((dto: CreateDonationResponseDTO) => {
          //Fix for Safari (window.open doesn't work in async functions)
          if (windowReference) {
            windowReference.href = getPaymentTemplate(dto.uuid);
          }
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(`Ошибка: ${e.data.message}`, {
            variant: 'error',
          });
        });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={fromTemplePage}>
      {(formikProps: FormikProps<FormValues>) => {
        const {
          values,
          errors,
          touched,
          submitForm,
          setFieldValue,
          setFieldTouched,
          isValid,
        } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        const handleSetRegion = (region: RegionDTO | null) => {
          setRegion(region);
          if (region === null) {
            handleSetFieldValue('temple', null);
            setFieldTouched('temple', false, false);
          }
        };
        const handleSetCurrentAmount = (amount: number) => {
          setFieldValue('donationValue', amount, true);
        };
        return (
          <Fragment>
            <Box
              sx={(theme: Theme) => ({
                boxShadow: '0px 4px 34px rgba(121, 151, 230, 0.5)',
                borderRadius: '16px',
                padding: theme.spacing(12),
                width: '100%',
                maxWidth: '100%',
                [theme.breakpoints.down('md')]: {
                  padding: theme.spacing(isMobile ? 2 : 8),
                },
              })}>
              <Grid container={true} direction={'column'} spacing={3}>
                <Grid item={true}>
                  <TypographyVezitsa variant={'h1'} lineHeight={'30px'}>
                    {'Благотворительное пожертвование'}
                  </TypographyVezitsa>
                </Grid>
                <Grid item={true} style={{ maxWidth: '100%' }}>
                  {fromTemplePage ? (
                    <Typography
                      variant={'h2'}
                      lineHeight={'28px'}
                      color={(theme: Theme) => theme.colors.grayText}>
                      {`${temple?.regionName}, ${temple?.name}`}
                    </Typography>
                  ) : (
                    <Grid container={true} direction={'row'} spacing={3}>
                      <Grid item={true} md={5} xs={12}>
                        <RegionSelectField
                          regions={regions || []}
                          region={region}
                          error={Boolean(!region && errors.temple)}
                          helperText={
                            !region && errors.temple ? 'Выберите регион' : ''
                          }
                          isLoading={isFetching}
                          onChange={(value: RegionDTO | null) => {
                            handleSetRegion(value);
                          }}
                        />
                      </Grid>
                      <Grid item={true} md={7} xs={12}>
                        <OutlinedSelect
                          displayEmpty={true}
                          label={'Церковь'}
                          placeholder={'Выберите церковь'}
                          withLabel={true}
                          disabled={!region}
                          value={values.temple?.id || null}
                          error={Boolean(
                            (!region && touched.temple) || errors.temple
                          )}
                          helperText={
                            (!region && touched.temple) || errors.temple
                              ? errors.temple
                              : ''
                          }
                          onChange={(event) => {
                            const selectTemple = region?.temples.find(
                              (temple) =>
                                temple.id === (event.target.value as number)
                            );
                            handleSetFieldValue('temple', selectTemple || null);
                          }}>
                          {region &&
                            region.temples &&
                            region.temples.map((value) => (
                              <MenuItem
                                key={value.id}
                                value={value.id}
                                style={{
                                  whiteSpace: 'normal',
                                  maxWidth: '100%',
                                }}>
                                {value.name}
                              </MenuItem>
                            ))}
                        </OutlinedSelect>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item={true}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true}>
                      <DonationButtonsGroup
                        currentAmount={values.donationValue}
                        setCurrentAmount={handleSetCurrentAmount}
                        label={'Сумма пожертвования'}
                        isMobile={isMobile}
                      />
                    </Grid>
                  </Grid>
                  {errors.donationValue && (
                    <Box position={'relative'}>
                      <Typography
                        sx={(theme: Theme) => ({
                          position: 'absolute',
                          left: 0,
                          top: '3px',
                          fontSize: theme.spacing(1.25),
                          lineHeight: theme.spacing(2),
                          color: theme.colors.red,
                        })}>
                        {errors.donationValue}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item={true}>
                  <Grid
                    container={true}
                    spacing={3}
                    direction={isMobile ? 'column' : 'row'}>
                    <Grid item={true} sm={6} xs={12}>
                      <OutlinedField
                        name={'fullName'}
                        label={'ФИО'}
                        withLabel={true}
                        required={!values.isAnonymous}
                        value={values.fullName}
                        error={touched.fullName && Boolean(errors.fullName)}
                        helperText={touched.fullName ? errors.fullName : ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleSetFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item={true} sm={6} xs={12}>
                      <OutlinedField
                        name={'email'}
                        label={'E-MAIL'}
                        withLabel={true}
                        required={true}
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email ? errors.email : ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleSetFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <OutlinedField
                        name={'comment'}
                        label={'Ваши комментарии и пожелания'}
                        withLabel={true}
                        multiline={true}
                        value={values.comment}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleSetFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} mt={isMobile ? 0 : 2}>
                  <Grid
                    container={true}
                    alignItems={'center'}
                    rowSpacing={{ sm: 1, xs: 3 }}
                    columnSpacing={1}>
                    <Grid item={true} md={3} sm={6} xs={12}>
                      <LoadingButton
                        color={'primary'}
                        disabled={!isValid}
                        isLoading={isDonationLoading}
                        variant={'contained'}
                        fullWidth={true}
                        onClick={submitForm}>
                        {'Пожертвовать'}
                      </LoadingButton>
                    </Grid>
                    <Grid item={true} md={4} sm={6} xs={6}>
                      <Grid
                        item={true}
                        container={true}
                        spacing={1.25}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <Grid item={true}>
                          <InvoiceBoxIcon />
                        </Grid>
                        <Grid item={true}>
                          <MirIcon />
                        </Grid>
                        <Grid item={true}>
                          <MastercardIcon />
                        </Grid>
                        <Grid item={true}>
                          <VisaIcon />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item={true} md={5} sm={12} xs={6}>
                      <Typography
                        variant={'body3'}
                        whiteSpace={'pre-line'}
                        color={(theme: Theme) => theme.colors.grayText}>
                        {
                          'Нажимая кнопку "Пожертвовать", я соглашаюсь и принимаю '
                        }
                        <a
                          href={GET_OFFER_URL}
                          target={'_blank'}
                          rel={'noopener noreferrer'}>
                          <TypographyLink>
                            {'условия использования сервиса'}
                          </TypographyLink>
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        );
      }}
    </Formik>
  );
};
