import { DonationDTO } from '@/types/DonationDTO';
import { Grid, Pagination, Theme, useMediaQuery } from '@mui/material';
import { PaymentMobileTableContainer } from '@/components/deacon/payments/PaymentMobileTableContainer';
import { PaymentTableContainer } from '@/components/deacon/payments/PaymentTableContainer';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { UserDTO } from '@/types/user/UserDTO';
import { getCurrentUser } from '@/services/authSlice';
import {
  getPageRequest,
  setDefaultPageValues,
  setPageNumber,
} from '@/services/templesSlice';
import { getTotalNumberOfPages } from '@/utils/pagination-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetDonationsPageQuery } from '@/services/api/donationsApiSlice';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

export const PaymentsPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const dispatch = useAppDispatch();
  const pageRequest = useAppSelector(getPageRequest);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const [donationsList, setDonationsList] = useState<DonationDTO[] | undefined>(
    undefined
  );
  const { data: donationsPage } = useGetDonationsPageQuery({
    pageSize: pageRequest.pageSize,
    pageNumber: pageRequest.pageNumber,
    templeId: authUser?.templeId,
  });
  const handlePageChange = (page: number) => {
    if (pageRequest.pageNumber !== page - 1) {
      dispatch(setPageNumber(page - 1));
    }
  };
  useEffect(() => {
    if (donationsPage && donationsPage.donations) {
      setDonationsList(donationsPage.donations);
    }
  }, [donationsPage, setDonationsList]);
  useEffect(() => {
    return () => {
      dispatch(setDefaultPageValues());
    };
  }, [dispatch]);
  return (
    <Grid container={true} direction={'column'} mt={'20px'}>
      <Grid item={true}>
        <TypographyVezitsa variant={'h1'} lineHeight={'30px'}>
          {'Реестр платежей'}
        </TypographyVezitsa>
      </Grid>
      <Grid item={true} mt={'20px'} style={{ maxWidth: '100%' }}>
        {isMobile ? (
          <PaymentMobileTableContainer donationsList={donationsList} />
        ) : (
          <PaymentTableContainer donationsList={donationsList} />
        )}
      </Grid>
      <Grid item={true} mt={3} mb={10}>
        <Grid
          container={true}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Grid item={true} mt={2} display={'flex'}>
            <Pagination
              defaultPage={pageRequest.pageNumber + 1}
              count={
                donationsPage && donationsPage.transactionsAmount > 0
                  ? getTotalNumberOfPages(
                      donationsPage.transactionsAmount,
                      pageRequest.pageSize
                    )
                  : 1
              }
              onChange={(e: ChangeEvent<unknown>, page: number) => {
                handlePageChange(page);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
