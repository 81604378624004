import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const VezitsaArrowIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 16 16'}
    sx={{ width: '16px', height: '16px' }}
    {...props}>
    <path
      d={
        'M6.52083 5.18717C6.10417 5.7844 5.13993 6.27818 4.34375 7.07259C4.03413 7.38152 3.57292 7.88509 3.57292 7.88509C2.80208 8.71148 2.27083 9.54481 1.97917 10.3851C1.6875 11.2184 1.54167 11.8573 1.54167 12.3018C1.54167 12.4684 1.58681 12.5726 1.67708 12.6143C1.76042 12.649 1.88542 12.6663 2.05208 12.6663C2.14236 12.6663 2.32292 12.6559 2.59375 12.6351C2.87153 12.6143 3.06944 12.6038 3.1875 12.6038C3.31944 12.6038 3.47917 12.6073 3.66667 12.6143C3.85417 12.6212 4 12.6247 4.10417 12.6247C4.24306 12.6247 4.34375 12.5969 4.40625 12.5413C4.47569 12.4858 4.52431 12.3781 4.55208 12.2184C4.57986 12.0587 4.60764 11.8156 4.63542 11.4893C4.73264 10.5934 4.84722 9.91287 4.97917 9.44759C5.11806 8.98231 5.30841 8.66984 5.55208 8.18717C5.68085 7.93212 5.75892 7.79211 5.89583 7.54134C6.51701 6.4036 7.20139 5.56217 7.625 4.70801C8.13194 5.54829 8.98309 6.41156 9.80208 7.54134C10.0075 7.82467 10.1329 7.97663 10.3229 8.27051C11.0616 9.41312 11.4063 10.0448 11.6563 11.4893C11.7465 12.0379 11.816 12.3643 11.8646 12.4684C11.9132 12.5726 12.0347 12.6247 12.2292 12.6247C12.3403 12.6247 12.4757 12.6212 12.6354 12.6143C12.7882 12.6073 12.9583 12.6038 13.1458 12.6038C13.2014 12.6038 13.2778 12.6073 13.375 12.6143C13.4792 12.6212 13.5521 12.6247 13.5938 12.6247C13.8229 12.6525 13.9965 12.6663 14.1146 12.6663C14.3229 12.6663 14.4653 12.6281 14.5417 12.5518C14.625 12.4754 14.6667 12.2913 14.6667 11.9997C14.6667 11.0344 14.309 10.0136 13.5938 8.93718C12.8854 7.86079 11.9549 6.62815 10.8021 5.23926C10.184 4.48926 9.78472 3.98579 9.60417 3.72884C9.42361 3.46495 9.33333 3.20106 9.33333 2.93718C9.33333 2.81912 9.3125 2.7462 9.27083 2.71843C9.23611 2.69065 9.14236 2.67676 8.98958 2.67676L7.52083 2.67676C7.25695 2.67676 7.09375 2.71148 7.03125 2.78092C6.96875 2.85037 6.9375 2.95801 6.9375 3.10384C6.9375 3.30523 6.97222 3.42329 7.04167 3.45801C7.11806 3.49273 7.15625 3.60384 7.15625 3.79134C7.15625 4.12467 6.94444 4.58995 6.52083 5.18717Z'
      }
    />
  </SvgIcon>
);
