import * as Yup from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { RestorePasswordFormValues } from '@/types/form/RestorePasswordFormValues';
import { emailSchema } from '@/validation/emailSchema';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  handleSubmit: (
    values: RestorePasswordFormValues,
    helpers?: FormikHelpers<RestorePasswordFormValues>
  ) => Promise<void>;
  isMobile?: boolean;
  isLoading?: boolean;
}

export const RestorePasswordForm: FC<Props> = (props: Props) => {
  const { handleSubmit, isLoading, isMobile } = props;
  const validationSchema = Yup.object({
    email: emailSchema,
  });

  const initialValues: RestorePasswordFormValues = {
    email: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {({
        values,
        errors,
        setFieldValue,
        submitForm,
        isValid,
      }: FormikProps<RestorePasswordFormValues>) => {
        const handleSetFieldValue = (
          field: string,
          value: string | unknown,
          isErrorField: boolean
        ) => {
          setFieldValue(field, value, isErrorField);
        };
        return (
          <Grid
            container={true}
            direction={'column'}
            wrap={'nowrap'}
            rowSpacing={isMobile ? 2 : 3}>
            <Grid item={true}>
              <Grid container={true} columnSpacing={3} rowSpacing={2}>
                <Grid item={true} xs={12}>
                  <OutlinedField
                    disabled={isLoading}
                    required={true}
                    name={'email'}
                    value={values.email}
                    placeholder={'Введите email'}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleSetFieldValue(
                        e.target.name,
                        e.target.value,
                        Boolean(errors.email)
                      );
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <LoadingButton
                    fullWidth={true}
                    disabled={isLoading || !isValid}
                    isLoading={isLoading}
                    color={'primary'}
                    variant={'contained'}
                    onClick={submitForm}>
                    {'Отправить'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
