import Image1 from '@assets/images/swiper/image1.png';
import Image2 from '@assets/images/swiper/image2.png';
import Image3 from '@assets/images/swiper/image3.png';
import Image4 from '@assets/images/swiper/image4.png';

import { Autoplay, Controller, Navigation, Pagination } from 'swiper';
import { Box } from '@mui/material';
import { MainImageContainer } from '@/components/common/swiper/MainImageContainer';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { FC } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
  setControlledSwiper: any;
}
//FIXME: fix swiper mobile. add class to style and swiper
export const MainSwiper: FC<Props> = (props: Props) => {
  const { setControlledSwiper } = props;

  return (
    <Box
      position={'absolute'}
      height={'736px'}
      left={0}
      right={0}
      top={0}
      zIndex={-1}>
      <Swiper
        loop={true}
        autoplay={{ delay: 60000 }}
        onSwiper={setControlledSwiper}
        modules={[Autoplay, Navigation, Pagination, Controller]}
        className={'mainSwiper'}>
        <SwiperSlide>
          <MainImageContainer src={Image1} shadow={0.4} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImageContainer src={Image2} shadow={0.4} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImageContainer src={Image3} shadow={0.4} />
        </SwiperSlide>
        <SwiperSlide>
          <MainImageContainer src={Image4} shadow={0.4} />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};
