import { Container, Grid, Skeleton, Theme, Typography } from '@mui/material';
import { FaqDTO } from '@/types/FaqDTO';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { useGetFaqQuery } from '@/services/api/helpApiSlice';
import CollapseHelpText from '@/components/help/CollapseHelpText';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  isMobile?: boolean;
}

export const FaqBlock: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { data: faq, isFetching: isFetching } = useGetFaqQuery();
  const [donationsFaq, setDonationsFaq] = useState<FaqDTO[]>([]);
  const [connectFaq, setConnectFaq] = useState<FaqDTO[]>([]);
  useEffect(() => {
    if (!isFetching && faq) {
      setDonationsFaq(
        faq.filter((value: FaqDTO) => value.type === 'DONATIONS')
      );
      setConnectFaq(faq.filter((value: FaqDTO) => value.type === 'CONNECT'));
    }
  }, [setDonationsFaq, setConnectFaq, isFetching, faq]);

  return (
    <Grid container={true} direction={'column'} mb={12}>
      {isMobile && (
        <Grid item={true} mb={2}>
          <TypographyVezitsa variant={'h1'} lineHeight={'24px'}>
            {'Часто задаваемые вопросы'}
          </TypographyVezitsa>
        </Grid>
      )}
      <Grid
        item={true}
        sx={{
          marginBottom: '12px',
        }}>
        <TypographyVezitsa
          variant={'h1'}
          sx={(theme: Theme) => ({
            [theme.breakpoints.down('sm')]: {
              fontSize: '20px',
            },
          })}>
          {'Пожертвования'}
        </TypographyVezitsa>
      </Grid>
      {isFetching ? (
        <Grid container={true} direction={'column'} rowSpacing={2}>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
        </Grid>
      ) : (
        donationsFaq.map((question: FaqDTO, index: number) => {
          return (
            <Grid item={true} key={index}>
              <CollapseHelpText tittle={question.tittle}>
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: question.answer,
                  }}
                />
              </CollapseHelpText>
            </Grid>
          );
        })
      )}
      <Grid
        item={true}
        mt={isMobile ? 3 : 6}
        sx={{
          marginBottom: '12px',
        }}>
        <TypographyVezitsa
          variant={'h1'}
          sx={(theme: Theme) => ({
            [theme.breakpoints.down('sm')]: {
              fontSize: '20px',
            },
          })}>
          {'Подключение к сервису'}
        </TypographyVezitsa>
      </Grid>
      {isFetching ? (
        <Grid container={true} direction={'column'} rowSpacing={2}>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
        </Grid>
      ) : (
        connectFaq.map((question: FaqDTO, index: number) => {
          return (
            <Grid item={true} key={index}>
              <CollapseHelpText tittle={question.tittle}>
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: question.answer,
                  }}
                />
              </CollapseHelpText>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};
