import { DONATION_AMOUNTS } from '@/utils/string-utils';
import { DonationButton } from '@/components/donations/card/DonationButton';
import { DonationField } from '@/components/donations/card/DonationField';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, useState } from 'react';

interface Props {
  label: string;
  currentAmount: number;
  setCurrentAmount: (amount: number) => void;
  isMobile?: boolean;
}

export const DonationButtonsGroup: FC<Props> = (props: Props) => {
  const { label, currentAmount, setCurrentAmount, isMobile } = props;
  const [fromDonationButton, isFromDonationButton] = useState<boolean>(true);

  return (
    <Grid container={true} spacing={1} direction={'column'}>
      <Grid item={true}>
        <Typography
          variant={'body3'}
          textTransform={'uppercase'}
          color={(theme: Theme) => theme.colors.grayText}>
          {label}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid
          container={true}
          rowSpacing={2}
          columnSpacing={isMobile ? 1 : 2}
          flex={1}>
          {DONATION_AMOUNTS.map((amount, index) => (
            <Grid item={true} key={index}>
              <DonationButton
                amount={amount}
                currentAmount={currentAmount}
                setAmount={setCurrentAmount}
                setFromButton={isFromDonationButton}
              />
            </Grid>
          ))}
          <Grid item={true} flex={1} minWidth={'15%'}>
            <DonationField
              currentAmount={currentAmount}
              setAmount={setCurrentAmount}
              setFromButton={isFromDonationButton}
              buttonSelected={fromDonationButton}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
