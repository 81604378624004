import { AuthenticationForm } from '@/components/auth/AuthenticationForm';
import { Box, Grid } from '@mui/material';
import { MainImageContainer } from '@/components/common/swiper/MainImageContainer';
import { RegistrationInfo } from '@/components/auth/RegistrationInfo';
import { Theme } from '@mui/material/styles';
import AuthTemple from '@assets/images/authTemple.png';
import React, { FC, Fragment } from 'react';

export const AuthenticationPage: FC = () => {
  return (
    <Fragment>
      <Box
        position={'absolute'}
        left={0}
        right={0}
        top={0}
        bottom={0}
        zIndex={-1}>
        <MainImageContainer src={AuthTemple} shadow={0.2} />
      </Box>
      <Grid
        container={true}
        mb={{ md: 20, xs: 10 }}
        direction={{ md: 'row', xs: 'column-reverse' }}
        rowSpacing={2}>
        <Grid item={true} md={6} xs={12} mt={4}>
          <RegistrationInfo />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            position={'relative'}
            width={'100%'}
            height={'min-content'}
            borderRadius={'12px'}
            maxWidth={(theme: Theme) => theme.spacing(54)}
            minWidth={(theme: Theme) => theme.spacing(44)}
            padding={(theme: Theme) => theme.spacing(6, 5, 7)}
            sx={{
              backgroundColor: 'white',
              margin: 'auto',
            }}>
            <Box display={'flex'} width={'100%'}>
              <AuthenticationForm />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
