import { ControllSwiper } from '@/components/main/swiper/ControllSwiper';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { MainHeader } from '@/components/main/MainHeader';
import { MainSwiper } from '@/components/common/swiper/MainSwiper';
import { ROUTE_TEMPLES, ROUTE_TEMPLE_PAGE } from '@/app/routes';
import { ReCaptchaDTO } from '@/types/ReCaptchaDTO';
import { Route, Switch } from 'react-router-dom';
import { TemplePage } from '@/components/temples/TemplePage';
import { TemplesBlock } from '@/components/temples/TemplesBlock';
import { useGetCaptchaKeyMutation } from '@/services/api/staticApiSlice';
import React, { FC, Fragment, useEffect, useState } from 'react';

export const MainPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const [getSiteKey, { isLoading }] = useGetCaptchaKeyMutation();
  const [siteKey, setSiteKey] = useState<string>('');

  const handleGetSiteKey = async () => {
    getSiteKey()
      .unwrap()
      .then((dto: ReCaptchaDTO) => {
        setSiteKey(dto.reCaptchaSiteKey);
      })
      .catch(() => {
        console.log("can't load site key");
      });
  };

  useEffect(() => {
    handleGetSiteKey();
  }, [siteKey]);

  const [controlledSwiper, setControlledSwiper] = useState(null);
  return (
    <Fragment>
      {!isLoading && (
        <GoogleReCaptchaProvider
          language={'ru-RU'}
          reCaptchaKey={siteKey ? siteKey : ''}>
          <Switch>
            <Route exact={true} path={ROUTE_TEMPLES}>
              <Grid container={true} mb={16}>
                <MainSwiper setControlledSwiper={setControlledSwiper} />
              </Grid>
              <Grid
                container={true}
                mb={8}
                minHeight={isMobile ? '350px' : '260px'}>
                <MainHeader isMobile={isMobile} />
              </Grid>
              <Grid container={true}>
                <ControllSwiper controlledSwiper={controlledSwiper} />
              </Grid>
              <Grid container={true} wrap={'nowrap'}>
                <TemplesBlock isMobile={isMobile} />
              </Grid>
            </Route>
            <Route path={ROUTE_TEMPLE_PAGE}>
              <TemplePage />
            </Route>
          </Switch>
        </GoogleReCaptchaProvider>
      )}
    </Fragment>
  );
};
