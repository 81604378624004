import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { ComponentVariant } from '@/types/ComponentVariant';
import { RegionDTO } from '@/types/RegionDTO';
import { Theme } from '@mui/material/styles';
import { newRegionLabel } from '@/utils/string-utils';
import React, {
  FC,
  Fragment,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react';

interface Props {
  regions?: RegionDTO[];
  region?: RegionDTO | null;
  isLoading?: boolean;
  error?: boolean;
  helperText?: ReactNode;
  onChange: (value: RegionDTO | null) => void;
  addNewRegion?: boolean;
  disabled?: boolean;
  withoutLabel?: boolean;
  variant?: ComponentVariant;
}

export const RegionSelectField: FC<Props> = (props: Props) => {
  const {
    regions,
    region,
    isLoading,
    error,
    helperText,
    onChange,
    addNewRegion,
    disabled,
    withoutLabel,
    variant,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const filter = createFilterOptions<RegionDTO>();
  return (
    <Autocomplete
      id={'select-reg'}
      open={open}
      disabled={disabled}
      loading={isLoading}
      options={regions || []}
      value={region}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(
        e: SyntheticEvent,
        value: AutocompleteValue<RegionDTO, undefined, undefined, undefined>
      ) => onChange(value)}
      fullWidth={true}
      blurOnSelect={true}
      noOptionsText={'Не найдено'}
      loadingText={'Загрузка ...'}
      openText={''}
      clearText={''}
      sx={(theme: Theme) => ({
        marginTop: theme.spacing(withoutLabel ? 0 : 3),
      })}
      PaperComponent={({ children }) => (
        <Paper sx={{ marginTop: '3px' }}>{children}</Paper>
      )}
      isOptionEqualToValue={(option: RegionDTO, value: RegionDTO) =>
        option.name === value.name
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (addNewRegion && params.inputValue !== '') {
          filtered.push({
            id: 0,
            name: params.inputValue,
            newRegionName: newRegionLabel(params.inputValue),
            temples: [],
          });
        }
        return filtered;
      }}
      getOptionLabel={(option: RegionDTO) => option.name}
      renderOption={(props, option: RegionDTO) => {
        return (
          <li {...props}>
            <Typography
              component={'div'}
              variant={'body1'}
              sx={{
                color: (theme: Theme) =>
                  option.newRegionName
                    ? theme.colors.primary
                    : theme.colors.textColor,
              }}>
              {option.newRegionName ? option.newRegionName : option.name}
            </Typography>
          </li>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={() => ({
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: '9px',
            },
            '& .MuiOutlinedInput-notchedOutline span': { display: 'none' },
          })}
          label={withoutLabel ? ' ' : 'Регион'}
          placeholder={'Выберите регион'}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            sx: {
              backgroundColor: (theme: Theme) =>
                variant === ComponentVariant.PRIMARY || region
                  ? theme.colors.white
                  : theme.colors.secondaryGrayBackground,
            },
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color={'inherit'} size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
