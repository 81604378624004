import { ChangePasswordForm } from '@/components/registration/ChangePasswordForm';
import { ChangePasswordFormValues } from '@/types/form/ChangePasswordFormValues';
import { ChangePasswordResponseDTO } from '@/types/user/ChangePasswordResponseDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { ROUTE_HOME, ROUTE_TEMPLES_TUTORIAL } from '@/app/routes';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { UserDTO } from '@/types/user/UserDTO';
import { getAuthenticated, signIn, signOut } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useSetNewPasswordMutation,
  useSignInMutation,
} from '@/services/api/authApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect, useState } from 'react';

export const RegistrationChangePasswordPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isAuthenticated: boolean = useAppSelector(getAuthenticated);
  const [setNewPassword, { isLoading: isLoading }] =
    useSetNewPasswordMutation();
  const [login] = useSignInMutation();
  const [token, setToken] = useState<string | null>(null);
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const handleSubmit = async (
    values: ChangePasswordFormValues
  ): Promise<void> => {
    if (token) {
      await setNewPassword({ token: token, password: values.password })
        .unwrap()
        .then((response: ChangePasswordResponseDTO) => {
          enqueueSnackbar('Регистрация завершена!', {
            variant: 'success',
          });
          if (response.username) {
            login({ username: response.username, password: values.password })
              .unwrap()
              .then((user: UserDTO) => {
                dispatch(signOut());
                dispatch(signIn(user));
                enqueueSnackbar('Выполнен вход!', {
                  variant: 'success',
                });
                if (!isResetPassword) {
                  history.replace(ROUTE_TEMPLES_TUTORIAL);
                } else {
                  history.replace(ROUTE_HOME);
                }
              })
              .catch((e: { status: number; data: ErrorDTO }) => {
                enqueueSnackbar(e.data.message, {
                  variant: 'error',
                });
              });
          } else {
            history.push(ROUTE_HOME);
          }
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data.message, {
            variant: 'error',
          });
        });
    }
  };
  useEffect(() => {
    setToken(new URLSearchParams(window.location.search).get('token'));
    if (new URLSearchParams(window.location.search).get('isResetPassword')) {
      setIsResetPassword(true);
    }
  }, [dispatch, history, isAuthenticated]);

  return (
    <Grid
      container={true}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ minHeight: '100%' }}>
      <Grid item={true} xs={true}>
        <Grid container={true} justifyContent={'center'}>
          <Grid
            container={true}
            direction={'column'}
            wrap={'nowrap'}
            justifyContent={'center'}
            maxWidth={'450px'}
            rowSpacing={3}
            sx={(theme: Theme) => ({
              backgroundColor: theme.colors.white,
              borderRadius: '12px',
              padding: theme.spacing(4),
            })}>
            <Grid item={true} mt={-3}>
              <TypographyVezitsa
                variant={'h2'}
                sx={(theme: Theme) => ({
                  lineHeight: '30px',
                  [theme.breakpoints.down('sm')]: {
                    lineHeight: theme.spacing(3.5),
                    fontSize: theme.spacing(2.25),
                  },
                })}>
                {isResetPassword ? 'Смена пароля' : 'Завершение регистрации'}
              </TypographyVezitsa>
            </Grid>
            <Grid item={true}>
              <Typography variant={'body2'} lineHeight={'18px'} pb={'18px'}>
                {!isResetPassword &&
                  'Вам осталось только придумать пароль и можно сразу начать пользоваться сервисом.'}
              </Typography>
              <Typography variant={'body2'} lineHeight={'18px'}>
                {
                  'Пароль должен содержать минимум 8 символов и букв латинского алфавита, в нем должны присутствовать как минимум одна цифра и одна заглавная буква.'
                }
              </Typography>
            </Grid>
            <Grid item={true}>
              <ChangePasswordForm
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                isMobile={isMobile}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
