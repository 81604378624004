import { Comment } from '@mui/icons-material';
import { DonationDTO } from '@/types/DonationDTO';
import { Grid, Paper, Theme, Tooltip, Typography } from '@mui/material';
import {
  formatCurrency,
  formatDonationName,
  formatStatus,
  getStatusColor,
} from '@/utils/string-utils';
import React, { FC } from 'react';
import moment from 'moment';

interface Props {
  donationsList: DonationDTO[] | undefined;
}

export const PaymentMobileTableContainer: FC<Props> = (props: Props) => {
  const { donationsList } = props;
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'} rowSpacing={1}>
      {donationsList?.map((donation, index) => (
        <Grid item={true} key={index}>
          <Paper elevation={0} sx={{ padding: '12px 16px' }}>
            <Grid container={true} direction={'column'} wrap={'nowrap'}>
              <Grid
                item={true}
                pb={1}
                style={{ maxWidth: '66%' }}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <Typography
                  variant={'body1'}
                  fontSize={'14px'}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {donation.isAnonymous || !donation.name
                    ? 'Анонимно'
                    : formatDonationName(donation.name)}
                </Typography>
                <Typography
                  variant={'body2'}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  color={(theme: Theme) => theme.colors.grayText}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {donation.email}
                </Typography>
              </Grid>
              <Grid item={true} pb={1.5}>
                <Grid container={true} columnSpacing={1.5}>
                  <Grid item={true}>
                    <Typography variant={'body1'} fontSize={'14px'}>
                      {formatCurrency(donation.amount, 0)}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography
                      variant={'body1'}
                      fontSize={'14px'}
                      color={getStatusColor(donation.status)}>
                      {formatStatus(donation.status)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} mb={1.5}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>
                    <Typography
                      variant={'body1'}
                      fontSize={'14px'}
                      color={(theme: Theme) => theme.colors.grayText}>
                      {moment(donation.date).format('DD.MM.YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Tooltip
                      title={donation.comment}
                      placement={'bottom-start'}
                      enterTouchDelay={0}
                      sx={{
                        maxWidth: '180px',
                        color: '#A9ABB2',
                        '&:hover': { color: 'black', cursor: 'pointer' },
                      }}>
                      <Comment style={{ display: 'block' }} />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
