import { Grid, Typography } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

interface Props {
  label: string;
}

export const InfoItem: FC<Props> = (props: PropsWithChildren<Props>) => {
  const { label, children } = props;

  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>{children}</Grid>
      <Grid item={true}>
        <Typography
          color={'white'}
          variant={'body2'}
          lineHeight={'18px'}
          whiteSpace={'pre-line'}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};
