import { Grid, Skeleton } from '@mui/material';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { useGetConnectContentQuery } from '@/services/api/helpApiSlice';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
}

export const ConnectPage: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { data: data, isFetching: isFetching } = useGetConnectContentQuery();
  return (
    <Grid
      container={true}
      direction={'column'}
      wrap={'nowrap'}
      marginBottom={6}>
      {isMobile && (
        <Grid item={true} mb={2}>
          <TypographyVezitsa variant={'h1'} lineHeight={'24px'}>
            {'Подключение к сервису'}
          </TypographyVezitsa>
        </Grid>
      )}
      {isFetching ? (
        <Grid item={true}>
          <Skeleton
            width={'100%'}
            height={'132px'}
            variant={'rectangular'}
            sx={{ borderRadius: '6px' }}
          />
        </Grid>
      ) : (
        data && (
          <Grid item={true}>
            <div
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          </Grid>
        )
      )}
    </Grid>
  );
};
