import { parsePhoneNumber } from 'react-phone-number-input';

export const DONATION_AMOUNTS: number[] = [50, 100, 300, 500, 1000];

export const formatCurrency = (
  value: number | string,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number,
  sign?: boolean
): string => {
  if (isNaN(Number(value))) {
    return 'NaN';
  }
  const format = new Intl.NumberFormat('ru-Ru', {
    style: sign === false ? undefined : 'currency',
    currency: 'RUB',
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits
      ? minimumFractionDigits
      : maximumFractionDigits,
  });
  return format.format(Number(value));
};

const isWhitespace = (char: string): boolean => {
  return /\s/.test(char);
};

export const removeWhitespace = (str: string): string => {
  return str.split(' ').join('');
};

export const isBlank = (value: string | undefined | null): boolean => {
  let length;
  if (value == undefined || (length = value.length) === 0) {
    return true;
  }
  for (let i = 0; i < length; i++) {
    if (!isWhitespace(value.charAt(i))) {
      return false;
    }
  }
  return true;
};

export const isNotBlank = (value: string | undefined | null): boolean => {
  return !isBlank(value);
};

export const formatStatus = (value: string): string => {
  switch (value) {
    case 'NEW_TRANSFER':
      return 'Создан';
    case 'PAID':
      return 'Оплачен';
    case 'DECLINED_BY_BOOKER':
      return 'Отклонен';
    case 'ACCEPTED_BY_BOOKER':
      return 'Подтвержден';
    case 'ACCEPTED_BY_SYSTEM':
      return 'Проведен';
    case 'CREDITED_TO_ACCOUNT':
      return 'Проведен';
    case 'SENT_TO_DEPARTMENT':
      return 'Проведен';
    case 'DIRECT_IN_DEPARTMENT':
      return 'Проведен';
    case 'CANCELED':
      return 'Отменен пользователем';
    case 'REFUND':
      return 'Возвращен';
    default:
      return 'Неизвестный статус';
  }
};

export const getStatusColor = (value: string): string => {
  switch (value) {
    case 'NEW_TRANSFER':
      return '#5e5e5e';
    case 'PAID':
      return '#eb8b46';
    case 'DECLINED_BY_BOOKER':
      return '#de606c';
    case 'ACCEPTED_BY_BOOKER':
      return '#4fad2d';
    case 'ACCEPTED_BY_SYSTEM':
      return '#4fad2d';
    case 'CREDITED_TO_ACCOUNT':
      return '#4fad2d';
    case 'SENT_TO_DEPARTMENT':
      return '#4fad2d';
    case 'DIRECT_IN_DEPARTMENT':
      return '#4fad2d';
    case 'CANCELED':
      return '#de606c';
    case 'REFUND':
      return '#de606c';
    default:
      return '#5e5e5e';
  }
};

export const newRegionLabel = (value: string): string => {
  return `Добавить: "${value}"`;
};

export const getNewRegionNameFromLabel = (
  newRegionLabel?: string
): string | undefined => {
  if (newRegionLabel) {
    return newRegionLabel.substring(
      newRegionLabel.indexOf('"') + 1,
      newRegionLabel.length - 1
    );
  }
  return undefined;
};

export const getNumberSpaceFormat = (
  number: string | undefined,
  divideBy: number
): string => {
  if (number && parseInt(number)) {
    const replace = '\\B(?=(\\d{divideBy})+(?!\\d))'.replace(
      'divideBy',
      divideBy.toString()
    );
    const re = new RegExp(replace, 'g');
    return number.replace(re, ' ');
  }
  return '';
};

export const formatDeaconFullName = (
  lastName?: string,
  firstName?: string,
  middleName?: string
): string => {
  return [
    lastName ? upperFirstCaseInSentence(lastName) : '',
    firstName ? upperFirstCaseInSentence(firstName) : '',
    middleName ? upperFirstCaseInSentence(middleName) : '',
  ]
    .filter((s: string) => isNotBlank(s))
    .join(' ');
};

export const upperFirstCase = (value: string): string => {
  if (isNotBlank(value)) {
    return removeExtraSpaces(value.charAt(0).toUpperCase() + value.slice(1));
  }
  return removeExtraSpaces(value);
};

export const upperFirstCaseInSentence = (value: string): string => {
  if (isNotBlank(value)) {
    const words: string[] = removeExtraSpaces(value).split(' ');
    return words.map((word: string) => upperFirstCase(word)).join(' ');
  }
  return removeExtraSpaces(value);
};

export const removeExtraSpaces = (value: string): string => {
  if (isNotBlank(value)) {
    return value
      .replace(/\s+/g, ' ')
      .replace(/^\s+|\s+$/g, '')
      .trim();
  }
  return '';
};

export const formatPhoneNumber = (phone?: string): string => {
  if (phone) {
    const phoneNumber = parsePhoneNumber(
      phone.includes('+') ? phone : '+' + phone
    );
    return phoneNumber ? phoneNumber.formatInternational() : phone;
  }
  return '';
};

export const phoneFieldNormalize = (value: string): string => {
  if (value[0] === '8') {
    return value.replace('8', '+7').replace(/[-\s]+/g, '');
  }
  return value.replace(/[-\s]+/g, '');
};

export const getFileType = (filename: string): string | undefined => {
  return filename.split('.').pop();
};

export const getFileMEME = (filename?: string): string => {
  return filename
    ? getFileType(filename)
      ? `data:image/${getFileType(filename)};base64,`
      : 'data:image/jpeg;base64,'
    : 'data:image/jpeg;base64,';
};

/**
 * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний.
 * @param  iNumber Integer Число на основе которого нужно сформировать окончание
 * @param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['яблоко', 'яблока', 'яблок']
 * @return String
 */
export const getNumEnding = (iNumber: number, aEndings: string[]): string => {
  let sEnding, i;
  iNumber = iNumber % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case 1:
        sEnding = aEndings[0];
        break;
      case 2:
      case 3:
      case 4:
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }
  return sEnding;
};

export const formatNumberWithSpaces = (value?: number): string | undefined => {
  if (value) {
    return value
      .toString()
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ');
  } else {
    return undefined;
  }
};

export const formatDonationName = (name: string): string => {
  const fio = name.split(' ');

  if (fio.length === 2) {
    return fio[1] + ' ' + fio[0][0] + '.';
  }
  return fio[1] + ' ' + fio[0][0] + '.' + ' ' + fio[2][0] + '.';
};

export const geMainRouteFromPath = (path: string): string => {
  return '/' + path.split('/').filter((path) => path.length > 0)[0];
};
