import { Grid, Typography } from '@mui/material';
import { ROUTE_AUTHENTICATION } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import { useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

export const Footer: FC = () => {
  const isAuthenticationPage = useRouteMatch({
    path: ROUTE_AUTHENTICATION.toString(),
    exact: true,
  });
  return (
    <Grid
      container={true}
      alignItems={'center'}
      spacing={4}
      justifyContent={'space-between'}>
      <Grid item={true}>
        <Typography
          variant={'body2'}
          color={(theme: Theme) =>
            isAuthenticationPage
              ? 'rgba(255,255,255,0.5)'
              : theme.colors.grayText
          }>
          <span>{`© 2008—${format(new Date(), 'yyyy')}`}</span>
          <br />
          <span>{'ООО «Интертелекомсервис»'}</span>
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography
          variant={'body2'}
          color={(theme: Theme) =>
            isAuthenticationPage
              ? 'rgba(255,255,255,0.5)'
              : theme.colors.grayText
          }>
          <span>{`© 2006—${format(new Date(), 'yyyy')}`}</span>
          <br />
          <span>{'ООО «ОРЦ»'}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};
