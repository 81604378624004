import { DonationDTO } from '@/types/DonationDTO';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { formatCurrency, formatDonationName } from '@/utils/string-utils';
import React, { FC } from 'react';
import moment from 'moment';

interface Props {
  donationsList: DonationDTO[] | undefined;
}

export const DonationTableContainer: FC<Props> = (props: Props) => {
  const { donationsList } = props;
  return (
    <TableContainer>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell
              align={'left'}
              sx={{ borderBottom: 'none', paddingLeft: '20px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Дата'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={{ borderBottom: 'none', paddingLeft: '15px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}
                sx={{ borderBottom: 'none' }}>
                {'ФИО'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={{ borderBottom: 'none', paddingLeft: '15px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}
                sx={{ borderBottom: 'none' }}>
                {'Церковь'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={{ borderBottom: 'none', paddingLeft: '15px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Комментарий'}
              </Typography>
            </TableCell>
            <TableCell
              align={'right'}
              sx={{ borderBottom: 'none', padding: '20px' }}>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                lineHeight={'24px'}>
                {'Сумма'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donationsList?.map((donation, index) => (
            <TableRow
              key={donation.id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
                backgroundColor:
                  (index + 1) % 2 === 1 ? 'white' : 'transparent',
              }}>
              <TableCell
                align={'left'}
                sx={{ borderBottom: 'none', padding: '20px' }}>
                <Typography fontSize={'14px'} fontWeight={'24px'}>
                  {moment(donation.date).format('DD.MM.YYYY')}
                </Typography>
              </TableCell>
              <TableCell
                align={'left'}
                sx={(theme: Theme) => ({
                  borderBottom: 'none',
                  padding: '15px',
                  maxWidth: '350px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '150px',
                  },
                })}>
                <Typography
                  fontSize={'14px'}
                  lineHeight={'24px'}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  {donation.isAnonymous || !donation.name
                    ? 'Анонимно'
                    : formatDonationName(donation.name)}
                </Typography>
              </TableCell>
              <TableCell
                align={'left'}
                sx={{ borderBottom: 'none', padding: '15px' }}>
                <Typography fontSize={'14px'} fontWeight={'24px'}>
                  {donation.templeName}
                </Typography>
              </TableCell>
              <TableCell
                align={'left'}
                sx={{ borderBottom: 'none', padding: '15px' }}>
                <Typography
                  fontSize={'14px'}
                  fontWeight={'24px'}
                  whiteSpace={'pre-line'}
                  sx={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                  }}>
                  {donation.comment}
                </Typography>
              </TableCell>
              <TableCell
                align={'right'}
                sx={{
                  borderBottom: 'none',
                  padding: '20px',
                }}>
                <Typography fontSize={'14px'} fontWeight={'24px'}>
                  {formatCurrency(donation.amount, 0)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
