import { AppBar, Container } from '@mui/material';
import { MobileHeader } from '@/components/common/MobileHeader';
import { NavigationContainer } from '@/components/common/navigation/NavigationContainer';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  transparent: boolean;
  isMobile?: boolean;
}

export const NavigationBar: FC<Props> = (props: Props) => {
  const { transparent, isMobile } = props;

  return (
    <AppBar
      position={'absolute'}
      sx={(theme: Theme) => ({
        backgroundColor: transparent ? 'transparent' : 'white',
        boxShadow: transparent ? 'none' : '0px 12px 24px rgba(0, 0, 0, 0.06)',
        borderBottom: '1px solid',
        borderBottomColor: transparent
          ? 'rgba(255,255,255,0.2)'
          : theme.colors.borderColor,
      })}>
      <Container
        sx={(theme: Theme) => ({
          display: 'flex',
          minHeight: '100%',
          padding: '0 24px',
          [theme.breakpoints.down('sm')]: {
            padding: '0 16px',
          },
        })}>
        {isMobile ? (
          <MobileHeader isMainPage={transparent} />
        ) : (
          <NavigationContainer isMainPage={transparent} />
        )}
      </Container>
    </AppBar>
  );
};
