import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const PaymentRegistryIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 59 119'}
    sx={{ width: '59px', height: '119px' }}
    {...props}>
    <g clipPath={'url(#clip0_1178_26836)'}>
      <path
        d={
          'M4.90749 115.839C4.6954 115.702 4.56284 115.41 4.56284 115.005V27.0403C4.5627 26.9337 4.57093 26.8272 4.58746 26.7219L0.0255547 24.1468C0.118931 23.5476 0.422123 22.9987 0.883392 22.5936L5.43962 25.165C5.50302 25.1146 5.57011 25.0688 5.64035 25.028L45.2016 2.69119C45.3302 2.61243 45.4778 2.56836 45.6295 2.56346C45.7235 2.56314 45.816 2.58606 45.8984 2.6301L44.5407 1.8637L44.3664 1.76558L41.3195 0.0513561C41.2375 0.0102774 41.1465 -0.0106889 41.0544 -0.00973444C40.9068 -0.00596167 40.763 0.036216 40.6378 0.112447L32.6426 4.64053L32.4683 4.73864L21.6553 10.8477L21.4811 10.9458L10.6681 17.0548L10.4939 17.1548L1.07845 22.4696C0.676537 22.7294 0.369492 23.1073 0.201668 23.5489C0.17894 23.6044 0.158108 23.6581 0.139172 23.7118C0.0506592 23.9588 0.0039711 24.2183 0.000934601 24.48V26.8274V36.0187V39.2102V51.6133V60.8231V64.0146V73.2244V76.4159V85.6276V88.8191V98.0289V101.22V110.432V112.435C-0.00198364 112.491 -0.00198364 112.546 0.000934601 112.602C0.00283813 112.737 0.0383072 112.869 0.10429 112.988C0.170269 113.107 0.264778 113.208 0.379673 113.283L0.491394 113.344L0.665623 113.444L4.91508 115.836'
        }
        fill={'#ffe49e'}
      />
      <path
        d={
          'M5.21255 115.927C5.36395 115.922 5.51141 115.878 5.64051 115.801L7.57398 114.708L7.7501 114.608L18.5612 108.499L18.7373 108.399L23.2216 105.869L19.6236 107.905C19.554 107.408 19.5167 106.907 19.5118 106.406V101.754L4.56869 110.188V115.001C4.56869 115.406 4.70125 115.699 4.91334 115.836C5.00384 115.894 5.11 115.924 5.21822 115.923L5.21255 115.927ZM46.2773 34.8265V25.4908V24.9355V13.0877V12.5323V3.48724C46.2773 3.05775 46.1296 2.74675 45.8986 2.63012C45.8162 2.58608 45.7236 2.56316 45.6297 2.56348C45.478 2.56838 45.3303 2.61245 45.2017 2.69121L5.64051 25.0188C5.57027 25.0596 5.50318 25.1054 5.43979 25.1557C4.98147 25.5638 4.6806 26.1135 4.58762 26.7126C4.57109 26.818 4.56286 26.9244 4.563 27.031V31.8442L46.2773 8.30226V34.8265Z'
        }
        fill={'#fff6e0'}
      />
      <path
        d={'M46.2793 86.6444L4.56306 110.19V31.8484L46.2793 8.30273V86.6444Z'}
        fill={'#ffc737'}
      />
      <path
        d={
          'M28.6658 15.9834L21.1195 20.2579C20.8998 20.3819 20.7123 20.4189 20.6309 20.2172C20.6019 20.1376 20.5878 20.0536 20.5892 19.9691C20.5919 19.774 20.6415 19.5821 20.7339 19.4092C20.8264 19.2362 20.9591 19.0868 21.1214 18.9731L28.7473 14.6691L28.8154 14.6357C29.0559 14.7005 29.0957 14.7468 29.0957 15.1004L29.0616 15.2004C29.0616 15.5836 28.9613 15.8187 28.6658 15.9834Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M2.17994 41.1194L2.09472 41.0695C1.91829 40.9466 1.7739 40.7849 1.67324 40.5974C1.57257 40.4099 1.51845 40.2019 1.51525 39.9902V34.8975C1.51525 34.481 1.77468 34.2903 2.09472 34.4699L2.17994 34.518C2.35416 34.6421 2.49627 34.8044 2.59491 34.9918C2.69355 35.1792 2.746 35.3865 2.74805 35.5973V40.6918C2.74805 41.1083 2.4905 41.299 2.17994 41.1194Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M2.17994 48.8854L2.09472 48.8373C1.91829 48.7145 1.7739 48.5528 1.67324 48.3653C1.57257 48.1778 1.51845 47.9698 1.51525 47.758V42.6709C1.51525 42.2562 1.77468 42.0637 2.09472 42.2451L2.17994 42.2932C2.35438 42.4172 2.49665 42.5794 2.59532 42.7668C2.69398 42.9543 2.74629 43.1617 2.74805 43.3725V48.4671C2.74805 48.8836 2.4905 49.0743 2.17994 48.8928V48.8854Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M19.6188 107.911L23.2168 105.875C23.2168 105.83 23.2168 105.786 23.2168 105.741V99.6582L19.5033 101.754V106.411C19.5081 106.913 19.5454 107.414 19.615 107.911'
        }
        fill={'#a592b5'}
      />
      <path
        d={
          'M19.5072 101.785L23.2207 99.6879V62.3248C23.2207 57.9392 20.8138 52.8299 17.4089 49.3848L14.5684 50.9916C17.5074 54.3849 19.5128 58.9907 19.5128 62.9931V99.0085'
        }
        fill={'#d19700'}
      />
      <path
        d={
          'M10.233 53.3521C10.2109 53.3324 10.1939 53.3078 10.1834 53.2805C10.1728 53.2532 10.1691 53.2237 10.1724 53.1947V46.6488C10.1738 46.5722 10.1935 46.4969 10.23 46.4291C10.2665 46.3612 10.3188 46.3027 10.3826 46.2582L45.8534 26.2373C45.8784 26.2219 45.9072 26.2136 45.9367 26.2132C45.9492 26.2104 45.9621 26.2104 45.9746 26.2132L43.1492 24.6212C43.1304 24.6101 43.1087 24.6043 43.0867 24.6045C43.0587 24.6054 43.0314 24.613 43.0072 24.6267L7.53635 44.6569C7.47277 44.7015 7.42081 44.7601 7.38462 44.8279C7.34845 44.8958 7.32907 44.971 7.32805 45.0475V51.5934C7.32486 51.6216 7.32832 51.6501 7.33817 51.6767C7.34802 51.7033 7.36399 51.7274 7.38486 51.7471L10.2254 53.3447'
        }
        fill={'#ffe49e'}
      />
      <path
        d={
          'M10.2982 53.3745C10.3276 53.3736 10.3563 53.3653 10.3815 53.3504L14.5609 50.992L17.4015 49.3851L15.7388 50.3237C15.2587 49.7393 14.744 49.183 14.1973 48.6576L11.3871 50.2441C11.3712 50.2541 11.3529 50.2598 11.334 50.2608C11.2867 50.2608 11.2507 50.2126 11.2507 50.1386V48.4929C11.2515 48.4405 11.2649 48.389 11.2899 48.3426C11.3149 48.2963 11.3508 48.2564 11.3946 48.2263L14.0193 46.7453L41.8434 31.0377L44.1613 29.7307L44.843 29.3457C44.8595 29.3354 44.8784 29.3296 44.8979 29.329C44.9491 29.329 44.985 29.3753 44.985 29.4512V31.0951C44.9842 31.1473 44.971 31.1985 44.9463 31.2448C44.9217 31.2911 44.8863 31.3311 44.843 31.3617L43.4852 32.1281C44.1039 32.605 44.6885 33.1227 45.235 33.6775L45.8543 33.3351C45.9182 33.2906 45.9705 33.2321 46.007 33.1643C46.0435 33.0964 46.0632 33.0211 46.0645 32.9445V26.393C46.0645 26.3023 46.0285 26.2393 45.9754 26.2208C45.963 26.2181 45.95 26.2181 45.9376 26.2208C45.9081 26.2212 45.8792 26.2296 45.8543 26.2449L10.3834 46.2584C10.3196 46.303 10.2673 46.3615 10.2308 46.4293C10.1943 46.4972 10.1746 46.5724 10.1732 46.649V53.1949C10.1699 53.224 10.1737 53.2534 10.1842 53.2807C10.1947 53.3081 10.2117 53.3326 10.2338 53.3523C10.2526 53.367 10.276 53.3748 10.3001 53.3745'
        }
        fill={'#fff6e0'}
      />
      <path
        d={
          'M15.7456 50.3239L17.4082 49.3853C16.8629 48.8294 16.2789 48.311 15.6603 47.834L14.2041 48.6559C14.7508 49.1813 15.2655 49.7377 15.7456 50.322'
        }
        fill={'#9f7ebf'}
      />
      <path
        d={
          'M42.8695 31.6221L15.0454 47.3037C14.9886 47.263 23.3379 53.0425 23.3417 62.3245C23.3455 71.6065 23.3417 105.351 23.3417 105.351C23.3417 105.351 23.2262 113.986 31.1702 119L59 103.294C59 103.294 52.338 99.8432 51.2169 91.0351C51.2169 83.5062 51.1696 46.741 51.1696 46.741C51.1696 46.741 51.1696 37.3349 42.8695 31.6221Z'
        }
        fill={'#ffe49e'}
      />
      <path
        d={
          'M28.3375 78.6694L33.4297 75.8074V75.2539L28.3394 78.1159L28.3375 78.6694Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M34.206 75.3708L36.0391 74.3415L36.041 73.7861L34.2079 74.8173L34.206 75.3708Z'
        }
        fill={'white'}
      />
      <path
        d={'M36.8164 73.9045L38.0625 73.2029V72.6494L36.8164 73.3492V73.9045Z'}
        fill={'white'}
      />
      <path
        d={
          'M28.3351 79.9354L31.1094 78.3767V77.8213L28.337 79.3819L28.3351 79.9354Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.886 77.9404L35.2871 76.0281V75.4746L31.8879 77.3851L31.886 77.9404Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.0626 75.591L37.3087 74.8913L37.3105 74.3359L36.0645 75.0375L36.0626 75.591Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M37.9903 74.5082L39.2363 73.8084V73.2549L37.9922 73.9547L37.9903 74.5082Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3325 81.2023L30.3398 80.073V79.5195L28.3325 80.6469V81.2023Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.1173 79.6357L31.8028 79.2507L31.8047 78.6953L31.1192 79.0822L31.1173 79.6357Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M32.5801 78.8137L33.8262 78.1121V77.5586L32.582 78.2584L32.5801 78.8137Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3321 82.4659L35.7402 78.3025V77.749L28.3321 81.9124V82.4659Z'}
        fill={'white'}
      />
      <path
        d={
          'M36.5166 77.8659L38.3497 76.8348L38.3516 76.2812L36.5185 77.3105L36.5166 77.8659Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3287 83.7326L30.336 82.6033L30.3379 82.0498L28.3306 83.179L28.3287 83.7326Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.1137 82.1662L35.0469 79.9558V79.4023L31.1156 81.6127L31.1137 82.1662Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M35.8224 79.5188L37.0684 78.819L37.0703 78.2637L35.8243 78.9653L35.8224 79.5188Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3281 84.9967L32.4336 82.6901V82.1348L28.3281 84.4432V84.9967Z'}
        fill={'white'}
      />
      <path
        d={
          'M33.2104 82.2516L36.1816 80.5837V80.0283L33.2123 81.6981L33.2104 82.2516Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.9576 80.1467L38.9649 79.0174L38.9668 78.4639L36.9595 79.5913L36.9576 80.1467Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3248 86.2626L29.7129 85.4832V84.9297L28.3267 85.7091L28.3248 86.2626Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M30.4892 85.0466L32.3223 84.0154L32.3242 83.4619L30.4911 84.493L30.4892 85.0466Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.1005 83.5783L36.0528 81.9178L36.0547 81.3643L33.1005 83.0248V83.5783Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3228 87.5294L32.5704 85.1413L32.5723 84.5859L28.3228 86.974V87.5294Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.3476 84.7028L37.3282 82.4665L37.3301 81.9111L33.3495 84.1493L33.3476 84.7028Z'
        }
        fill={'white'}
      />
      <path
        d={'M38.1075 82.0296L39.3535 81.328V80.7744L38.1075 81.4742V82.0296Z'}
        fill={'white'}
      />
      <path
        d={
          'M40.047 80.9368L41.293 80.237L41.2949 79.6816L40.0489 80.3814L40.047 80.9368Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3201 88.7941L30.8652 87.3631V86.8096L28.3201 88.2406V88.7941Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2285 68.6118L47.2833 68.0193L47.2852 67.4658L46.2285 68.0582V68.6118Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M43.7534 70.0043L46.2285 68.6121V68.0586L43.7553 69.4489L43.7534 70.0043Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2284 69.8764L47.2813 69.2858L47.2832 68.7305L46.2284 69.3229V69.8764Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M44.5071 70.844L46.2285 69.8758V69.3223L44.509 70.2886L44.5071 70.844Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2284 71.1401L47.2794 70.5496L47.2812 69.9961L46.2284 70.5866V71.1401Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M45.2722 71.6773L46.2285 71.1405V70.5869L45.2741 71.1238L45.2722 71.6773Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.3267 72.3493L47.2773 71.8161V71.2607L46.2282 71.8513V72.2937L46.3267 72.3493Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2297 72.405L46.3281 72.3495L46.2297 72.2939V72.405Z'}
        fill={'white'}
      />
      <path
        d={
          'M44.3575 73.4566L46.2285 72.4051V71.8516L44.3594 72.9031L44.3575 73.4566Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2281 73.6694L47.2734 73.0807L46.8947 72.8697L46.2243 73.24V73.662L46.2281 73.6694ZM47.2715 72.8771V72.6494L47.0689 72.7623L47.2715 72.8771Z'
        }
        fill={'white'}
      />
      <path
        d={'M47.2734 73.082L46.8985 72.8691L47.2734 73.082Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2282 73.2475L46.8985 72.8698L47.2735 73.0827L47.2754 73.0809V72.8846L47.0728 72.7717L47.2754 72.6569V72.5273L46.2282 73.1161V73.2475Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M45.2684 74.2102L46.2285 73.6697V73.1162L45.2703 73.6568L45.2684 74.2102Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2281 74.9333L47.2734 74.3465V73.793L46.2281 74.3798V74.9333Z'}
        fill={'white'}
      />
      <path
        d={
          'M44.7268 75.7775L46.2285 74.9334V74.3799L44.7287 75.224L44.7268 75.7775Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2281 76.198L47.2696 75.613L47.2715 75.0576L46.2281 75.6444V76.198Z'
        }
        fill={'white'}
      />
      <path
        d={'M45.8839 76.3924L46.2285 76.198V75.6445L45.8839 75.8371V76.3924Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.228 77.4628L47.2676 76.8777L47.2695 76.3242L46.228 76.9092V77.4628Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.581 78.3883L46.2285 77.4627V76.9092L44.581 77.8348V78.3883Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2279 78.7265L47.2657 78.1434L47.2676 77.5898L46.2279 78.173V78.7265Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.7211 79.5742L46.2285 78.7264V78.1729L44.7211 79.0207V79.5742Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.6434 86.9262L32.7247 86.319L32.7266 85.7637L31.6434 86.3727V86.9262Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.5042 85.8795L35.9395 84.5096L35.9414 83.9561L33.5061 85.326L33.5042 85.8795Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3375 64.1079L33.4297 61.2459V60.6924L28.3394 63.5525L28.3375 64.1079Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M34.206 60.8092L36.0391 59.7781L36.041 59.2246L34.2079 60.2557L34.206 60.8092Z'
        }
        fill={'white'}
      />
      <path
        d={'M36.8164 59.3411L38.0625 58.6395V58.0859L36.8164 58.7875V59.3411Z'}
        fill={'white'}
      />
      <path
        d={
          'M28.3351 65.372L31.1094 63.8133V63.2598L28.337 64.8185L28.3351 65.372Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.886 63.376L35.2871 61.4655V60.9102L31.8879 62.8225L31.886 63.376Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.0626 61.0295L37.3087 60.3279L37.3105 59.7744L36.0645 60.4742L36.0626 61.0295Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M37.9903 59.9465L39.2363 59.2468V58.6914L37.9922 59.3912L37.9903 59.9465Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3325 66.6378L30.3398 65.5086V64.9551L28.3325 66.0843V66.6378Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.1173 65.0724L31.8028 64.6873L31.8047 64.1338L31.1192 64.5188L31.1173 65.0724Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M32.5801 64.2502L33.8262 63.5505V62.9951L32.582 63.6967L32.5801 64.2502Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3321 67.9043L35.7402 63.7391V63.1855L28.3321 67.3489V67.9043Z'}
        fill={'white'}
      />
      <path
        d={
          'M36.5166 63.3024L38.3497 62.2713L38.3516 61.7178L36.5185 62.7489L36.5166 63.3024Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3287 69.1691L30.336 68.0417L30.3379 67.4863L28.3306 68.6156L28.3287 69.1691Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M31.1137 67.6046L35.0469 65.3924V64.8389L31.1156 67.0493L31.1137 67.6046Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M35.8224 64.9554L37.0684 64.2557L37.0703 63.7021L35.8243 64.4019L35.8224 64.9554Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3281 70.4351L32.4336 68.1267V67.5713L28.3281 69.8798V70.4351Z'}
        fill={'white'}
      />
      <path
        d={
          'M33.2104 67.6901L36.1816 66.0203V65.4668L33.2123 67.1347L33.2104 67.6901Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.9576 65.5831L38.9649 64.4539L38.9668 63.9004L36.9595 65.0278L36.9576 65.5831Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3248 71.6991L29.7129 70.9197V70.3662L28.3267 71.1456L28.3248 71.6991Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M30.4892 70.4831L32.3223 69.452L32.3242 68.8984L30.4911 69.9296L30.4892 70.4831Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.1005 69.0148L36.0528 67.3543L36.0547 66.8008L33.1005 68.4613V69.0148Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.3228 72.966L32.5704 70.5779L32.5723 70.0244L28.3228 72.4125V72.966Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.3476 70.1413L37.3282 67.9031L37.3301 67.3496L33.3495 69.5859L33.3476 70.1413Z'
        }
        fill={'white'}
      />
      <path
        d={'M38.1075 67.4661L39.3535 66.7645V66.2109L38.1075 66.9126V67.4661Z'}
        fill={'white'}
      />
      <path
        d={
          'M40.047 66.3734L41.293 65.6736L41.2949 65.1201L40.0489 65.8199L40.047 66.3734Z'
        }
        fill={'white'}
      />
      <path
        d={'M28.3201 74.2325L30.8652 72.7996V72.2461L28.3201 73.6771V74.2325Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2285 54.0483L47.2833 53.4559L47.2852 52.9023L46.2285 53.4966V54.0483Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M43.7534 55.4399L46.2285 54.0478V53.4961L43.7553 54.8864L43.7534 55.4399Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2284 55.3148L47.2813 54.7225L47.2832 54.1689L46.2284 54.7613V55.3148Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M44.5071 56.2806L46.2285 55.3143V54.7607L44.509 55.7271L44.5071 56.2806Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2284 56.5767L47.2794 55.9861L47.2812 55.4326L46.2284 56.0232V56.5767Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M45.2722 57.1157L46.2285 56.5769V56.0234L45.2741 56.5621L45.2722 57.1157Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2282 57.8414L47.2773 57.2527V56.6992L46.2282 57.2879V57.8414Z'}
        fill={'white'}
      />
      <path
        d={
          'M44.3575 58.8931L46.2285 57.8416V57.2881L44.3594 58.3396L44.3575 58.8931Z'
        }
        fill={'white'}
      />
      <path
        d={'M46.2282 59.1062L47.2754 58.5193V57.9658L46.2282 58.5527V59.1062Z'}
        fill={'white'}
      />
      <path
        d={
          'M45.2684 59.6458L46.2285 59.1053V58.5518L45.2703 59.0923L45.2684 59.6458Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2281 60.3623L46.4781 60.2216L46.2281 60.081V60.3623ZM46.6561 60.1309L47.2734 59.7829V59.2275L46.2281 59.8144V59.8884L46.6542 60.1291'
        }
        fill={'white'}
      />
      <path
        d={'M46.4785 60.2314L46.2285 60.0889L46.4785 60.2314Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.4782 60.2314L46.6543 60.1314L46.2282 59.8926V60.0888L46.4782 60.2314Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M44.7268 61.2141L46.2285 60.3699V59.8164L44.7287 60.6606L44.7268 61.2141Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M46.2281 61.6347L47.2696 61.0497L47.2715 60.6776L47.1086 60.5869L46.2281 61.0812V61.6347Z'
        }
        fill={'white'}
      />
      <path
        d={'M47.2715 60.6776L47.1086 60.5869L47.2715 60.6776Z'}
        fill={'white'}
      />
      <path
        d={'M47.2715 60.6775V60.4961L47.1086 60.5868L47.2715 60.6775Z'}
        fill={'white'}
      />
      <path
        d={'M45.8839 61.8289L46.2285 61.6346V61.0811L45.8839 61.2754V61.8289Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.228 62.8992L47.2676 62.3161L47.2695 61.7607L46.228 62.3457V62.8992Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.581 63.8267L46.2285 62.8992V62.3457L44.581 63.2713V63.8267Z'}
        fill={'white'}
      />
      <path
        d={
          'M46.2279 64.163L47.2657 63.5799L47.2676 63.0264L46.2279 63.6095V64.163Z'
        }
        fill={'white'}
      />
      <path
        d={'M44.7211 65.0108L46.2285 64.1629V63.6094L44.7211 64.4572V65.0108Z'}
        fill={'white'}
      />
      <path
        d={
          'M31.6434 72.3619L32.7247 71.7547L32.7266 71.2012L31.6434 71.8084V72.3619Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M33.5042 71.3178L35.9395 69.9479L35.9414 69.3926L33.5061 70.7625L33.5042 71.3178Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M34.6037 91.8501L38.7149 89.5379L38.7168 88.9844L34.6056 91.2966L34.6037 91.8501Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M39.9122 88.8656L41.5332 87.9567V87.4014L39.9141 88.3121L39.9122 88.8656Z'
        }
        fill={'white'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_1178_26836'}>
        <rect
          width={'59'}
          height={'119'}
          fill={'white'}
          transform={'matrix(-1 0 0 1 59 0)'}
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
