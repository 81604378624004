import { MobileMenuButton } from '@/components/common/MobileMenuButton';
import { Theme } from '@mui/material/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  to: string;
  label: string;
  size?: 'small' | 'medium';
  activeOnlyWhenExact?: boolean;
  onBeforeRedirect?: () => void;
  isRedirect?: boolean;
}

export const MobileMenuLink: FC<Props> = (props: Props) => {
  const { label, to, activeOnlyWhenExact, onBeforeRedirect, isRedirect, size } =
    props;
  const history = useHistory();
  const match = useRouteMatch({
    path: to.toString(),
    exact: activeOnlyWhenExact,
  });
  const handleClick = (): void => {
    if (isRedirect) {
      location.href = to;
    } else {
      if (onBeforeRedirect) {
        onBeforeRedirect();
      }
      history.push(to);
    }
  };
  return (
    <MobileMenuButton
      sx={(theme: Theme) => ({
        color: match ? theme.colors.textColor : theme.colors.grayText,
      })}
      onClick={handleClick}
      size={size}>
      {label}
    </MobileMenuButton>
  );
};
