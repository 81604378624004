import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CheckboxCheckedIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={'M0 6C0 2.68629 2.68629 0 6 0H24V18C24 21.3137 21.3137 24 18 24H0V6Z'}
      fill={'#ffc737'}
    />
    <path
      d={
        'M11.105 16.6041L11.0873 16.6217L6.6875 12.2218L8.12018 10.7892L11.105 13.774L15.879 9L17.3117 10.4327L11.1226 16.6218L11.105 16.6041Z'
      }
      fill={'white'}
    />
  </SvgIcon>
);
