import {
  Chip,
  Grid,
  ToggleButton,
  ToggleButtonProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends ToggleButtonProps {
  chipColor?: string;
  count?: number;
  label: string;
}

export const ToggleButtonItem: FC<Props> = (props: Props) => {
  const { label, count, chipColor, ...other } = props;
  return (
    <ToggleButton
      sx={(theme: Theme) => ({
        textTransform: 'none',
        borderColor: other.selected
          ? 'transparent'
          : theme.colors.secondaryBorderColor,
        outlineColor: theme.colors.primary,
        outlineWidth: other.selected ? '1px' : '0px',
        outlineStyle: 'solid',
      })}
      {...other}>
      <Grid container={true} spacing={1} alignItems={'center'} wrap={'nowrap'}>
        <Grid item={true}>
          <Typography
            display={'inherit'}
            fontSize={'16px'}
            fontWeight={400}
            lineHeight={'24px'}
            color={other.selected ? 'primary' : 'black'}>
            {label}
          </Typography>
        </Grid>
        {count
          ? count > 0 && (
              <Grid item={true}>
                <Chip
                  size={'small'}
                  label={count}
                  sx={{
                    backgroundColor: chipColor,
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    height: '16px',
                    width: 'auto',
                  }}
                />
              </Grid>
            )
          : null}
      </Grid>
    </ToggleButton>
  );
};
