import { ChangePasswordForm } from '@/components/registration/ChangePasswordForm';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { ROUTE_REGISTRATION_RESTORE_SUCCESS } from '@/app/routes';
import { RestorePasswordForm } from '@/components/registration/RestorePasswordForm';
import { RestorePasswordFormValues } from '@/types/form/RestorePasswordFormValues';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { useHistory } from 'react-router-dom';
import { useRestorePasswordMutation } from '@/services/api/authApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

export const RegistrationRestorePage: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [restorePassword, { isLoading: isLoading }] =
    useRestorePasswordMutation();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const handleSubmit = async (
    values: RestorePasswordFormValues
  ): Promise<void> => {
    await restorePassword(values.email)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Запрос отправлен!', {
          variant: 'success',
        });
        history.push(ROUTE_REGISTRATION_RESTORE_SUCCESS);
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        enqueueSnackbar(e.data.message, {
          variant: 'error',
        });
      });
  };

  return (
    <Grid
      container={true}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ minHeight: '100%' }}>
      <Grid item={true}>
        <Grid
          container={true}
          direction={'column'}
          wrap={'nowrap'}
          justifyContent={'center'}
          maxWidth={'450px'}
          sx={(theme: Theme) => ({
            backgroundColor: theme.colors.white,
            borderRadius: '12px',
            padding: theme.spacing(4),
          })}>
          <Grid item={true} mb={1.25}>
            <TypographyVezitsa variant={'h2'} lineHeight={'30px'}>
              {'Восстановление пароля'}
            </TypographyVezitsa>
          </Grid>
          <Grid item={true} mb={isMobile ? 2 : 4}>
            <Typography variant={'body2'} lineHeight={'18px'} pb={'18px'}>
              {'Введите адрес электронной почты указанный при регистрации'}
            </Typography>
          </Grid>
          <Grid item={true}>
            <RestorePasswordForm
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
