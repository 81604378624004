import { Comment } from '@mui/icons-material';
import { DonationDTO } from '@/types/DonationDTO';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { format } from 'date-fns';
import {
  formatCurrency,
  formatStatus,
  getStatusColor,
} from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  donationsList: DonationDTO[] | undefined;
}

export const PaymentTableContainer: FC<Props> = (props: Props) => {
  const { donationsList } = props;
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: '100%',
          borderCollapse: 'separate',
          borderSpacing: '0 8px',
        }}>
        <TableHead>
          <TableRow
            sx={(theme: Theme) => ({
              backgroundColor: theme.colors.attachFileBackground,
            })}>
            <TableCell
              align={'left'}
              sx={(theme: Theme) => ({
                border: '1px solid ' + theme.colors.secondaryBorderColor,
                borderRightWidth: '0',
                borderRadius: '6px 0 0 6px',
              })}>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'18px'}>
                {'Дата'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={(theme: Theme) => ({
                border: '1px solid ' + theme.colors.secondaryBorderColor,
                borderRightWidth: '0',
                borderLeftWidth: '0',
                paddingLeft: '0px',
              })}>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'18px'}>
                {'ФИО'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={(theme: Theme) => ({
                border: '1px solid ' + theme.colors.secondaryBorderColor,
                borderRightWidth: '0',
                borderLeftWidth: '0',
                paddingLeft: '0px',
              })}>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'18px'}>
                {'E-mail'}
              </Typography>
            </TableCell>
            <TableCell
              align={'left'}
              sx={(theme: Theme) => ({
                border: '1px solid ' + theme.colors.secondaryBorderColor,
                borderRightWidth: '0',
                borderLeftWidth: '0',
                paddingLeft: '0px',
              })}>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'18px'}>
                {'Статус'}
              </Typography>
            </TableCell>
            <TableCell
              align={'right'}
              sx={(theme: Theme) => ({
                border: '1px solid ' + theme.colors.secondaryBorderColor,
                borderRightWidth: '0',
                borderLeftWidth: '0',
                paddingLeft: '0px',
              })}>
              <Typography
                fontSize={'14px'}
                fontWeight={500}
                lineHeight={'18px'}>
                {'Сумма'}
              </Typography>
            </TableCell>
            <TableCell
              align={'right'}
              sx={(theme: Theme) => ({
                border: '1px solid ' + theme.colors.secondaryBorderColor,
                borderLeftWidth: '0',
                borderRadius: '0 6px 6px 0',
              })}
            />
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '&:before': {
              content: '"@"',
              display: 'block',
              lineHeight: '1px',
              textIndent: '-99999px',
            },
          }}>
          {donationsList?.map((donation) => (
            <TableRow
              key={donation.id}
              sx={{
                backgroundColor: 'white',
              }}>
              <TableCell
                align={'left'}
                sx={(theme: Theme) => ({
                  border: '1px solid ' + theme.colors.secondaryBorderColor,
                  borderRightWidth: '0',
                  paddingLeft: '16px',
                  borderRadius: '6px 0 0 6px',
                  color: theme.colors.grayText,
                })}>
                {format(donation.date, 'dd.MM.yyyy')}
              </TableCell>
              <TableCell
                align={'left'}
                sx={(theme: Theme) => ({
                  maxWidth: '350px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  border: '1px solid ' + theme.colors.secondaryBorderColor,
                  borderRightWidth: '0',
                  borderLeftWidth: '0',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '150px',
                  },
                })}>
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  {donation.isAnonymous || !donation.name
                    ? 'Анонимно'
                    : donation.name}
                </Typography>
              </TableCell>
              <TableCell
                align={'left'}
                sx={(theme: Theme) => ({
                  border: '1px solid ' + theme.colors.secondaryBorderColor,
                  color: theme.colors.grayText,
                  borderRightWidth: '0',
                  borderLeftWidth: '0',
                })}>
                {donation.email}
              </TableCell>
              <TableCell
                align={'left'}
                sx={(theme: Theme) => ({
                  border: '1px solid ' + theme.colors.secondaryBorderColor,
                  borderRightWidth: '0',
                  borderLeftWidth: '0',
                  color: getStatusColor(donation.status),
                })}>
                {formatStatus(donation.status)}
              </TableCell>
              <TableCell
                align={'right'}
                sx={(theme: Theme) => ({
                  border: '1px solid ' + theme.colors.secondaryBorderColor,
                  borderRightWidth: '0',
                  borderLeftWidth: '0',
                  paddingRight: '16px',
                })}>
                {formatCurrency(donation.amount, 0)}
              </TableCell>
              <TableCell
                align={'right'}
                sx={(theme: Theme) => ({
                  border: '1px solid ' + theme.colors.secondaryBorderColor,
                  borderLeftWidth: '0',
                  borderRadius: '0 6px 6px 0',
                })}>
                <Tooltip
                  title={donation.comment}
                  placement={'bottom-start'}
                  sx={{
                    maxWidth: '180px',
                    color: '#A9ABB2',
                    '&:hover': { color: 'black', cursor: 'pointer' },
                  }}>
                  <Comment style={{ display: 'block' }} />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
