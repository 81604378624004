import * as Yup from 'yup';
import { AppDispatch } from '@/app/store';
import { ButtonLink } from '@/components/common/ButtonLink';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import {
  MESSAGE_INVALID_CREDENTIALS,
  MESSAGE_REQUIRED,
  MESSAGE_UNKNOWN,
} from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PasswordAdornment } from '@/components/common/PasswordAdornment';
import {
  ROUTE_ADMIN_PANEL,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_REGISTRATION_RESTORE,
} from '@/app/routes';
import { SignInDTO } from '@/types/user/SignInDTO';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { UserDTO } from '@/types/user/UserDTO';
import { clearCreateTempleForm } from '@/services/templesSlice';
import { emailSchema } from '@/validation/emailSchema';
import { getCurrentUser, signIn, signOut } from '@/services/authSlice';
import { isBlank } from '@/utils/string-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import { useSignInMutation } from '@/services/api/authApiSlice';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

interface FormValues {
  email?: string;
  password?: string;
}

interface Props {
  isMobile?: boolean;
}

export const AuthenticationForm: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const [login, { isLoading }] = useSignInMutation();
  const [isAfterAuth, setIsAfterAuth] = useState<boolean>(false);
  const dispatch: AppDispatch = useAppDispatch();
  const history = useHistory();
  const initialValues: FormValues = {
    email: '',
    password: '',
  };
  const initialErrors: FormikErrors<FormValues> = {
    email: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
    password: isBlank(initialValues.password) ? MESSAGE_REQUIRED : undefined,
  };
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    email: emailSchema.required(MESSAGE_REQUIRED).nullable(),
    password: Yup.string().required(MESSAGE_REQUIRED).nullable(),
  });
  useEffect(() => {
    if (authUser && !isAfterAuth) {
      history.replace(ROUTE_HOME);
    }
  }, [authUser, history, isAfterAuth]);
  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ): Promise<void> => {
    const dto: SignInDTO = {
      username: values.email || '',
      password: values.password || '',
    };
    await login(dto)
      .unwrap()
      .then((user: UserDTO) => {
        dispatch(signIn(user));
        dispatch(clearCreateTempleForm());
        setIsAfterAuth(true);
        if (user.roles.indexOf('ROLE_ADMIN') > -1) {
          history.replace(ROUTE_ADMIN_PANEL);
        } else if (user.roles.indexOf('ROLE_DEACON') > -1) {
          history.replace(ROUTE_PAYMENTS);
        } else {
          history.replace(ROUTE_HOME);
        }
      })
      .catch((e: { status: number }) => {
        const { setFieldError } = helpers;
        if (e.status === 401) {
          setFieldError('email', '');
          setFieldError('password', MESSAGE_INVALID_CREDENTIALS);
        } else {
          setFieldError('email', '');
          setFieldError('password', MESSAGE_UNKNOWN);
        }
        setError(true);
        dispatch(signOut());
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        submitForm,
      }: FormikProps<FormValues>) => {
        const handleSetFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
          setFieldTouched(e.target.name, true, false);
          setFieldValue(e.target.name, e.target.value, true);
          setError(false);
        };
        return (
          <Grid
            container={true}
            direction={'column'}
            alignItems={'center'}
            spacing={5}>
            <Grid item={true} mt={isMobile ? 4 : 0}>
              <Grid
                container={true}
                direction={'column'}
                alignItems={'center'}
                spacing={2}>
                <Grid item={true}>
                  <TypographyVezitsa
                    variant={'h1'}
                    fontSize={'24px'}
                    lineHeight={'28px'}>
                    {'Вход в кабинет церкви'}
                  </TypographyVezitsa>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} width={'100%'} mt={isMobile ? '-20px' : 0}>
              <Grid container={true} direction={'column'} spacing={3}>
                <Grid item={true} width={'100%'}>
                  <OutlinedField
                    name={'email'}
                    label={'Электронная почта'}
                    withLabel={true}
                    required={true}
                    value={values.email}
                    error={error || (touched.email && Boolean(errors.email))}
                    helperText={touched.email ? errors.email : ''}
                    onChange={handleSetFieldValue}
                  />
                </Grid>
                <Grid item={true} width={'100%'}>
                  <OutlinedField
                    name={'password'}
                    label={'Пароль'}
                    withLabel={true}
                    required={true}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    error={
                      error || (touched.password && Boolean(errors.password))
                    }
                    helperText={touched.password ? errors.password : ''}
                    endAdornment={
                      <PasswordAdornment
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                      />
                    }
                    onChange={handleSetFieldValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} width={'100%'}>
              <Grid
                container={true}
                direction={'column'}
                alignItems={'center'}
                spacing={3}>
                <Grid item={true} width={'100%'}>
                  <LoadingButton
                    variant={'contained'}
                    color={'primary'}
                    fullWidth={true}
                    type={'submit'}
                    onClick={submitForm}
                    isLoading={isLoading}
                    disabled={isLoading}>
                    {'Войти'}
                  </LoadingButton>
                </Grid>
                <Grid item={true}>
                  <ButtonLink
                    to={ROUTE_REGISTRATION_RESTORE}
                    sx={{ color: 'rgba(62, 157, 237, 1)' }}>
                    <Typography variant={'body2'} fontWeight={400}>
                      {'Вспомнить пароль'}
                    </Typography>
                  </ButtonLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
