import { ConnectPage } from '@/components/help/ConnectPage';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import { FaqBlock } from '@/components/help/FaqBlock';
import { FeedbackBlock } from '@/components/help/FeedbackBlock';
import {
  ROUTE_HELP_CONNECT,
  ROUTE_HELP_FAQ,
  ROUTE_HELP_FEEDBACK,
  ROUTE_HELP_WORK,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { WorkBlock } from '@/components/help/WorkBlock';
import HelpMenu from '@/components/help/HelpMenu';
import React, { FC, Fragment } from 'react';

export const HelpPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Grid container={true} direction={'column'} mt={'20px'}>
      {!isMobile && (
        <Grid item={true}>
          <TypographyVezitsa variant={'h1'} lineHeight={'30px'}>
            {'Помощь'}
          </TypographyVezitsa>
        </Grid>
      )}
      <Grid item={true} mt={isMobile ? '16px' : '50px'}>
        <Grid container={true} wrap={'nowrap'}>
          {!isMobile && (
            <Fragment>
              <Grid item={true} xs={3} pr={3}>
                <HelpMenu />
              </Grid>
              <Grid item={true} pr={4}>
                <Divider orientation={'vertical'} />
              </Grid>
            </Fragment>
          )}
          <Grid item={true} xs={isMobile ? 12 : 9}>
            <Switch>
              <Route path={ROUTE_HELP_WORK}>
                <WorkBlock isMobile={isMobile} />
              </Route>
              <Route path={ROUTE_HELP_FAQ}>
                <FaqBlock isMobile={isMobile} />
              </Route>
              <Route path={ROUTE_HELP_CONNECT}>
                <ConnectPage isMobile={isMobile} />
              </Route>
              <Route exact={true} path={ROUTE_HELP_FEEDBACK}>
                <FeedbackBlock isMobile={isMobile} />
              </Route>
              <Redirect to={ROUTE_HELP_WORK} />
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
