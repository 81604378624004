import { AddressDTO } from '@/types/form/AddressDTO';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  Paper,
  TextField,
} from '@mui/material';
import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { ComponentVariant } from '@/types/ComponentVariant';
import { DaDataResponseDTO } from '@/types/form/DaDataResponseDTO';
import { Theme } from '@mui/material/styles';
import { isBlank, isNotBlank } from '@/utils/string-utils';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetAddressesSuggestionsQuery } from '@/services/api/daDataApiSlice';
import React, {
  FC,
  Fragment,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react';

interface Props {
  error?: boolean;
  helperText?: ReactNode;
  initialAddress: string;
  setAddressToFrom: (value?: string) => void;
  disabled?: boolean;
  variant?: ComponentVariant;
}

export const AddressField: FC<Props> = (props: Props) => {
  const {
    error,
    helperText,
    setAddressToFrom,
    initialAddress,
    disabled,
    variant,
  } = props;
  const [address, setAddress] = useState<AddressDTO | null>(
    isBlank(initialAddress)
      ? null
      : { value: initialAddress, unrestricted_value: initialAddress, data: {} }
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const {
    data: response,
    isFetching,
    isLoading,
  } = useGetAddressesSuggestionsQuery(searchQuery, {
    skip: isBlank(searchQuery),
  });
  const loading = isFetching || isLoading;
  const handleAddressee = useDebounce<string>((addressee: string): void => {
    setSearchQuery(addressee);
  }, 500);
  const handleSearch = (addressee: string): void => {
    handleAddressee(addressee);
    setTextFieldValue(addressee);
  };
  const getCurrentOptions = (response?: DaDataResponseDTO): AddressDTO[] => {
    if (
      response?.suggestions &&
      response.suggestions.length > 0 &&
      !isBlank(searchQuery)
    ) {
      return response.suggestions;
    } else {
      return [];
    }
  };
  const handleChangeAddress = (address: AddressDTO | null) => {
    setAddress(address);
    setAddressToFrom(address?.value);
  };
  return (
    <Autocomplete
      id={'address-autocomplete'}
      open={open}
      disabled={disabled}
      loading={loading}
      options={getCurrentOptions(response)}
      value={address}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        handleSearch(address?.value || '');
      }}
      onChange={(
        e: SyntheticEvent,
        value: AutocompleteValue<AddressDTO, undefined, undefined, undefined>
      ) => handleChangeAddress(value)}
      fullWidth={true}
      blurOnSelect={true}
      noOptionsText={'Не найдено'}
      loadingText={'Загрузка ...'}
      openText={''}
      clearText={''}
      sx={(theme: Theme) => ({
        marginTop: theme.spacing(3),
      })}
      PaperComponent={({ children }) => (
        <Paper sx={{ marginTop: '3px' }}>{children}</Paper>
      )}
      isOptionEqualToValue={(option: AddressDTO, value: AddressDTO) =>
        option.value === value.value
      }
      getOptionLabel={(option: AddressDTO) => option.value}
      filterOptions={(options) => options}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'input') {
          handleSearch(newInputValue);
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={() => ({
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: '9px',
            },
            '& .MuiOutlinedInput-notchedOutline span': { display: 'none' },
          })}
          label={'Адрес церкви'}
          placeholder={'Введите адрес церкви'}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            sx: {
              backgroundColor: (theme: Theme) =>
                variant === ComponentVariant.PRIMARY ||
                isNotBlank(textFieldValue) ||
                address
                  ? theme.colors.white
                  : theme.colors.secondaryGrayBackground,
            },
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color={'inherit'} size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
