import { Box, Theme, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import { InformationTemplate } from '@/components/common/InformationTemplate';
import React, { FC } from 'react';

export const RegistrationSuccessPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Grid
      container={true}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ minHeight: '100%' }}>
      <Grid item={true} xs={true}>
        <InformationTemplate
          title={'Заявка успешно подана!'}
          content={
            'В данный момент заявка проходит ' +
            'проверку модератором. Ответ с ' +
            'результатами регистрации вам ' +
            'придет на указанную почту.'
          }
          isMobile={isMobile}
        />
      </Grid>
    </Grid>
  );
};
