import { Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  amount: number;
  currentAmount: number;
  setAmount: (amount: number) => void;
  setFromButton: (selected: boolean) => void;
}

export const DonationButton: FC<Props> = (props: Props) => {
  const { amount, setAmount, currentAmount, setFromButton } = props;
  const selected: boolean = currentAmount === amount;
  const handleSetAmount = (amount: number) => {
    setAmount(amount);
    setFromButton(true);
  };

  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      sx={(theme: Theme) => ({
        color: theme.colors.primary,
        borderRadius: '29px',
        padding: selected ? theme.spacing(1.5, 2) : theme.spacing(1.375, 2),
        [theme.breakpoints.down('md')]: {
          padding: selected ? theme.spacing(1.125, 1.5) : theme.spacing(1, 1.5),
        },
        [theme.breakpoints.down('sm')]: {
          padding: selected
            ? theme.spacing(0.25, 1.125)
            : theme.spacing(0.125, 1.125),
        },
      })}
      onClick={() => handleSetAmount(amount)}>
      <Typography
        ml={2}
        mr={2}
        sx={(theme: Theme) => ({
          color: 'black',
          fontSize: '24px',
          lineHeight: '24px',
          margin: theme.spacing(0, 2),
          [theme.breakpoints.down('md')]: {
            fontSize: '20px',
            lineHeight: '20px',
            margin: theme.spacing(0),
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            lineHeight: '28px',
          },
        })}>
        {formatCurrency(amount ? amount : 0, 0)}
      </Typography>
    </Button>
  );
};
