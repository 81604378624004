import { AuthenticationForm } from '@/components/auth/AuthenticationForm';
import { Box, Button, Grid, IconButton, Tabs, Typography } from '@mui/material';
import { LoginMobileSwiper } from '@/components/common/swiper/LoginMobileSwiper';
import { ROUTE_REGISTRATION } from '@/app/routes';
import { SecondaryArrowIcon } from '@/app/icons/SecondaryArrowIcon';
import { Theme } from '@mui/material/styles';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { useHistory } from 'react-router-dom';
import React, { FC, useState } from 'react';

export const AuthenticationMobilePage: FC = () => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(true);
  const handleClick = () => {
    history.push(ROUTE_REGISTRATION);
  };

  return (
    <Grid
      container={true}
      direction={'column'}
      wrap={'nowrap'}
      sx={{
        height: '100%',
        marginLeft: '-16px',
        width: 'calc(100% + 32px)',
      }}>
      <Grid item={true} xs={true} sx={{ minHeight: '352px' }}>
        <Box position={'relative'} height={'100%'}>
          <Box
            position={'absolute'}
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={3}>
            <Box
              sx={{
                height: open ? '100%' : '0',
                transition: 'height 0.4s ease-in-out',
                overflow: 'hidden',
                backgroundColor: 'transparent',
                padding: '0px 16px',
              }}>
              <AuthenticationForm isMobile={true} />
            </Box>
            <Box
              sx={{
                height: open ? '24px' : '100%',
                transition: 'height 0.4s ease-in-out',
                overflow: 'hidden',
                backgroundColor: 'white',
                padding: '0px 16px',
              }}>
              <Grid
                container={true}
                direction={'column'}
                wrap={'nowrap'}
                justifyContent={'center'}
                alignItems={'center'}>
                <Grid item={true} pb={0.5}>
                  <IconButton
                    disableRipple={true}
                    onClick={() => setOpen(!open)}
                    sx={{ padding: '2px' }}>
                    <SecondaryArrowIcon
                      style={{
                        display: 'block',
                        color: 'rgba(223, 221, 228, 1)',
                        transform: !open ? 'rotate(180deg)' : '',
                        transition: 'transform 0.3s',
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item={true} pb={3}>
                  <TypographyVezitsa
                    variant={'h1'}
                    align={'center'}
                    sx={(theme: Theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        fontSize: '24px',
                        lineHeight: '28px',
                      },
                    })}>
                    {'Подключение к сервису пожертвований'}
                  </TypographyVezitsa>
                </Grid>
                <Grid item={true} pb={4}>
                  <Typography align={'center'}>
                    <Typography variant={'body2'} component={'span'}>
                      {
                        'Сервис позволит вашей церкви получать электронные пожертвования. Подробее о работе сервиса в разделе  '
                      }
                    </Typography>
                    <Typography
                      variant={'body2'}
                      component={'span'}
                      fontWeight={500}>
                      {'«О портале»'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item={true} style={{ maxWidth: '100%' }} pb={1}>
                  <LoginMobileSwiper />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item={true}>
        <Box
          sx={(theme: Theme) => ({
            backgroundColor: theme.colors.white,
            padding: theme.spacing(4, 2),
          })}>
          <Button
            variant={open ? 'outlined' : 'contained'}
            fullWidth={true}
            onClick={handleClick}
            sx={(theme: Theme) => ({
              color: theme.colors.textColor,
            })}>
            {'Подключиться к сервису'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
