import { Button, ButtonProps, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import React, { FC } from 'react';

export const MobileMenuButton: FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <Button
      color={'inherit'}
      fullWidth={true}
      variant={'text'}
      sx={{
        ...props.sx,
        textTransform: 'none',
        padding: (theme: Theme) =>
          props.size === 'small'
            ? theme.spacing(0.75, 0.875)
            : theme.spacing(1.25, 1),
      }}
      {...props}>
      <Grid
        container={true}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'baseline'}
        spacing={1}>
        <Grid item={true}>
          <TypographyVezitsa
            align={'center'}
            whiteSpace={'pre-line'}
            sx={(theme: Theme) => ({
              lineHeight:
                props.size === 'small' ? theme.spacing(2.5) : theme.spacing(4),
              fontSize:
                props.size === 'small' ? theme.spacing(2.25) : theme.spacing(4),
              fontWeight: 400,
            })}>
            {props.children}
          </TypographyVezitsa>
        </Grid>
      </Grid>
    </Button>
  );
};
