import { Paper } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

export const TempleFormPaper: FC = (prop) => {
  return (
    <Paper
      sx={(theme: Theme) => ({
        background: theme.colors.white,
        border: '1px solid ' + theme.colors.borderColor,
        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.06)',
        borderRadius: '6px',
        padding: '32px 24px',
        marginBottom: '48px',
      })}>
      {prop.children}
    </Paper>
  );
};
