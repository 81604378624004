import { Tab, TabProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends TabProps {
  secondary?: boolean;
}

export const NavigationTab: FC<Props> = (props: Props) => {
  const { secondary, ...other } = props;
  return (
    <Tab
      id={`navigation-tab-${other.tabIndex}`}
      disableRipple={true}
      sx={(theme: Theme) => ({
        padding: '39px 16px',
        lineHeight: '14px',
        fontSize: '14px',
        fontWeight: 400,
        color: secondary ? 'rgba(255, 255, 255, 0.5)' : theme.colors.grayText,
        '&:hover': {
          color: secondary ? 'white' : 'black',
          opacity: 1,
        },
        '&.Mui-selected': {
          color: secondary ? 'white' : 'black',
        },
        [theme.breakpoints.down('md')]: {
          padding: '39px 8px',
        },
      })}
      {...other}
    />
  );
};
