import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CalendarIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 22 22'}
    sx={{ width: '22px', height: '22px' }}
    {...props}>
    <path
      d={
        'M20.8566 2.21763H16.3566V0.503348C16.3566 0.385491 16.2602 0.289062 16.1423 0.289062H14.6423C14.5244 0.289062 14.428 0.385491 14.428 0.503348V2.21763H7.57087V0.503348C7.57087 0.385491 7.47444 0.289062 7.35659 0.289062H5.85659C5.73873 0.289062 5.6423 0.385491 5.6423 0.503348V2.21763H1.1423C0.668192 2.21763 0.285156 2.60067 0.285156 3.07478V20.8605C0.285156 21.3346 0.668192 21.7176 1.1423 21.7176H20.8566C21.3307 21.7176 21.7137 21.3346 21.7137 20.8605V3.07478C21.7137 2.60067 21.3307 2.21763 20.8566 2.21763ZM19.7852 19.7891H2.21373V9.61049H19.7852V19.7891ZM2.21373 7.78906V4.14621H5.6423V5.43192C5.6423 5.54978 5.73873 5.64621 5.85659 5.64621H7.35659C7.47444 5.64621 7.57087 5.54978 7.57087 5.43192V4.14621H14.428V5.43192C14.428 5.54978 14.5244 5.64621 14.6423 5.64621H16.1423C16.2602 5.64621 16.3566 5.54978 16.3566 5.43192V4.14621H19.7852V7.78906H2.21373Z'
      }
      fill={'#ffc737'}
    />
  </SvgIcon>
);
