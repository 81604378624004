import { DonationCard } from '@/components/donations/card/DonationCard';
import { Grid, Paper } from '@mui/material';
import { LastDonations } from '@/components/donations/LastDonations';
import { RegionsList } from '@/components/temples/RegionsList';
import { SuccessDonationDialog } from '@/components/donations/SuccessDonationDialog';
import { TemplesList } from '@/components/temples/TemplesList';
import { Theme } from '@mui/material/styles';
import { TutorialDialog } from '@/components/deacon/TutorialDialog';
import {
  clearRegion,
  getCurrentRegion,
  getCurrentTemple,
} from '@/services/templesSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  useGetRegionsQuery,
  useGetTemplesCountQuery,
} from '@/services/api/templesApiSlice';
import React, { FC, Fragment, useEffect, useState } from 'react';

interface Props {
  isMobile?: boolean;
}

export const TemplesBlock: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { data: regions, isFetching } = useGetRegionsQuery();
  const dispatch = useAppDispatch();
  const currentRegion = useAppSelector(getCurrentRegion);
  const currentTemple = useAppSelector(getCurrentTemple);
  const [openTutorial, setOpenTutorial] = useState<boolean>(false);
  const [openSuccessDonation, setOpenSuccessDonation] =
    useState<boolean>(false);
  const [templeName, setTempleName] = useState<string>('');
  const [sum, setSum] = useState<number>(0);

  const { data: count } = useGetTemplesCountQuery({
    regionId: currentRegion ? currentRegion.id : undefined,
    templeId: currentTemple ? currentTemple.id : undefined,
  });
  useEffect(() => {
    return () => {
      dispatch(clearRegion());
    };
  }, [dispatch]);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const isNew: string | null = params.get('isNew');
    if (isNew) {
      setOpenTutorial(true);
    }
  }, []);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const donationTempleName: string | null = params.get('temple');
    const donationSum: string | null = params.get('sum');
    if (donationTempleName && donationSum) {
      setTempleName(donationTempleName);
      setSum(parseInt(donationSum));
      setOpenSuccessDonation(true);
    }
  }, []);

  return (
    <Fragment>
      <Grid container={true} direction={'column'}>
        <Grid item={true} style={{ maxWidth: '100%' }}>
          <Paper
            sx={(theme: Theme) => ({
              padding: isMobile ? theme.spacing(2) : theme.spacing(5, 8),
              borderRadius: theme.spacing(1),
              boxShadow: isMobile
                ? '0px 4px 34px 0px rgba(121, 151, 229, 0.5)'
                : '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
            })}>
            <RegionsList regions={regions} isFetching={isFetching} />
          </Paper>
        </Grid>
        <Grid item={true} mt={3}>
          <TemplesList count={count} isMobile={isMobile} />
        </Grid>
        <Grid
          id={'donationsCard'}
          item={true}
          mt={isMobile ? 4 : 7}
          mb={isMobile ? 5 : 10}
          style={{ maxWidth: '100%' }}>
          <DonationCard
            regions={regions}
            isFetching={isFetching}
            isMobile={isMobile}
          />
        </Grid>
        <Grid item={true} mb={isMobile ? 5 : 10} style={{ maxWidth: '100%' }}>
          <LastDonations isMobile={isMobile} />
        </Grid>
        <TutorialDialog open={openTutorial} setOpen={setOpenTutorial} />
        <SuccessDonationDialog
          open={openSuccessDonation}
          setOpen={setOpenSuccessDonation}
          donationAmount={sum}
          templeName={templeName}
        />
      </Grid>
    </Fragment>
  );
};
