import { Button, Grid, Typography } from '@mui/material';
import { ROUTE_HELP, ROUTE_HELP_FEEDBACK, ROUTE_HOME } from '@/app/routes';
import { SuccessCheckIcon } from '@/app/icons/SuccessCheckIcon';
import { Theme } from '@mui/material/styles';
import { TypographyLink } from '@/components/common/TypographyLink';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  title: string;
  content: string;
  isMobile?: boolean;
}

export const InformationTemplate: FC<Props> = (props: Props) => {
  const { title, content, isMobile } = props;
  const history = useHistory();
  const handleClick = (route: string) => {
    history.push(route);
  };

  return (
    <Grid container={true} justifyContent={'center'}>
      <Grid
        container={true}
        direction={'column'}
        wrap={'nowrap'}
        justifyContent={'center'}
        padding={(theme: Theme) => theme.spacing(4)}
        maxWidth={'480px'}
        minWidth={'240px'}
        sx={{
          backgroundColor: (theme: Theme) => theme.colors.white,
          borderRadius: '12px',
        }}>
        <Grid item={true} pb={3}>
          <Grid
            container={true}
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}>
            <Grid item={true}>
              <SuccessCheckIcon style={{ display: 'block' }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} pb={1.5}>
          <TypographyVezitsa
            fontSize={'20px'}
            fontWeight={500}
            align={'center'}
            lineHeight={'24px'}>
            {title}
          </TypographyVezitsa>
        </Grid>
        <Grid item={true} pb={3}>
          <Typography
            fontSize={'16px'}
            fontWeight={400}
            lineHeight={'24px'}
            align={'center'}
            color={(theme: Theme) => theme.colors.grayText}
            whiteSpace={'pre-line'}>
            {content}
            <Typography component={'span'}>
              {' Если у вас появятся вопросы уточнить их можно через раздел '}
              <a href={ROUTE_HELP} target={'_self'} rel={'noopener noreferrer'}>
                <TypographyLink>{'Помощь'}</TypographyLink>
              </a>
            </Typography>
          </Typography>
        </Grid>
        <Grid item={true}>
          <Grid
            container={true}
            columnSpacing={3}
            rowSpacing={2}
            justifyContent={'center'}>
            <Grid item={true} sm={5} xs={12}>
              <Button
                variant={'contained'}
                color={'primary'}
                fullWidth={true}
                onClick={() => handleClick(ROUTE_HOME)}>
                {'Хорошо'}
              </Button>
            </Grid>
            <Grid item={true} sm={6} xs={12}>
              <Button
                variant={'outlined'}
                color={'secondary'}
                fullWidth={true}
                onClick={() => handleClick(ROUTE_HELP_FEEDBACK)}>
                {'Задать вопрос'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
