import { Button, Container, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import { getNumEnding } from '@/utils/string-utils';
import { useGetTemplesCountQuery } from '@/services/api/templesApiSlice';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
}

export const MainHeader: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { data: count } = useGetTemplesCountQuery({});
  const handleScroll = () => {
    document
      .getElementById('donationsCard')
      ?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Grid container={true} direction={'column'} spacing={3}>
      <Grid item={true}>
        <TypographyVezitsa
          color={'white'}
          variant={'h1'}
          whiteSpace={'pre-line'}
          textAlign={'center'}
          sx={(theme: Theme) => ({
            fontSize: '64px',
            lineHeight: '64px',
            [theme.breakpoints.down('sm')]: {
              fontSize: '28px',
              lineHeight: '28px',
            },
          })}>
          {'Сервис пожертвований\nправославным храмам'}
        </TypographyVezitsa>
      </Grid>
      <Grid item={true}>
        <Typography
          color={'white'}
          fontSize={isMobile ? '16px' : '14px'}
          lineHeight={'21px'}
          whiteSpace={'pre-line'}
          textAlign={'center'}>
          <span>
            {`У нас подключено ${count ? count : 0} ${getNumEnding(
              count ? count : 0,
              ['церковь', 'церкви', 'церквей']
            )} во всех регионах страны.`}
          </span>
          <br />
          <span>
            {
              'Чтобы сделать пожертвование, пожалуйста, выберите церковь из списка или воспользуйтесь фильтром'
            }
          </span>
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true} justifyContent={'center'}>
          <Button
            variant={'contained'}
            fullWidth={true}
            onClick={handleScroll}
            sx={{ maxWidth: '175px', borderRadius: isMobile ? '29px' : '6px' }}>
            {'Пожертвовать'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
