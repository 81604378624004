import { Collapse, Grid, Theme, Typography } from '@mui/material';
import { DownIcon } from '@/app/icons/DownIcon';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  tittle?: string;
  hide?: boolean;
}

const CollapseHelpText: FC<Props> = (props: PropsWithChildren<Props>) => {
  const { tittle, hide, children } = props;
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (hide != undefined) {
      setOpen(!hide);
    }
  }, [hide]);
  return (
    <Grid
      container={true}
      direction={'column'}
      sx={(theme: Theme) => ({
        marginBottom: '8px',
        backgroundColor: theme.colors.white,
        padding: '0 16px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
        borderRadius: '6px',
      })}>
      <Grid item={true} sx={{ cursor: 'pointer' }}>
        <Grid
          container={true}
          direction={'column'}
          onClick={() => setOpen(!open)}>
          <Grid item={true} sx={{ padding: '12px 0px' }}>
            <Grid
              container={true}
              justifyContent={'space-between'}
              wrap={'nowrap'}>
              <Grid item={true}>
                <TypographyVezitsa
                  fontWeight={500}
                  fontSize={'18px'}
                  lineHeight={'24px'}>
                  {tittle}
                </TypographyVezitsa>
              </Grid>
              <Grid item={true}>
                <Grid container={true}>
                  <DownIcon
                    sx={{
                      color: '#a9abb2',
                      transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                      transition: 'transform 0.3s',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={open}>
        <Grid item={true} sx={{ padding: '8px 0px 16px' }}>
          {children}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default CollapseHelpText;
