import { Grid, Typography } from '@mui/material';
import { TypographyVezitsa } from '@/components/common/TypographyVezitsa';
import React, { FC } from 'react';

export const AboutPage: FC = () => {
  return (
    <Grid container={true} direction={'column'} mt={'20px'}>
      <Grid item={true}>
        <TypographyVezitsa variant={'h1'} lineHeight={'30px'}>
          {'О портале'}
        </TypographyVezitsa>
      </Grid>
      <Grid item={true} mt={'20px'}>
        <Typography
          fontSize={'18px'}
          lineHeight={'28px'}
          whiteSpace={'pre-line'}>
          {'Проект "Благодаритель" представляет портал для взаимодействия с православными церквями.\n' +
            'Сервис позволяет регистрировать свои церкви в системе, а также осуществлять пожертвования в выбранные церкви.'}
        </Typography>
      </Grid>
    </Grid>
  );
};
