import { DONATION_AMOUNTS } from '@/utils/string-utils';
import { Grid, OutlinedInput } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { fullNumberRangeRegExp } from '@/utils/validation-utils';
import { useDebounce } from '@/app/hooks/useDebounce';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  currentAmount: number;
  setAmount: (amount: number) => void;
  setFromButton: (selected: boolean) => void;
  buttonSelected: boolean;
  isMobile?: boolean;
}

export const DonationField: FC<Props> = (props: Props) => {
  const currencySign = '₽';
  const { currentAmount, setAmount, setFromButton, buttonSelected, isMobile } =
    props;
  const [selected, setSelected] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (buttonSelected) {
      setSelected(false);
      setValue('');
    }
    if (!DONATION_AMOUNTS.includes(currentAmount)) {
      setSelected(true);
    }
  }, [buttonSelected, currentAmount]);

  const handleAmount = useDebounce<number>((amount: number): void => {
    setAmount(amount);
  }, 500);

  const handleChange = (amount: string) => {
    const e = getAmount(amount);
    if (parseInt(e) && parseInt(e) > 0) {
      setValue(e);
      handleAmount(+e);
      setFromButton(false);
    } else {
      setValue('');
      handleAmount(100);
      setFromButton(true);
    }
  };

  const getFormatCurrency = (value: string) => {
    return value.length > 0 ? value + ' ' + currencySign : '';
  };

  const getOnlyNumber = (amount: string) => {
    return amount.replace(' ', '').replace(currencySign, '');
  };

  const getAmount = (value: string) => {
    return (value.includes(' ') && value.includes(currencySign)) ||
      value.length === 1
      ? getOnlyNumber(value)
      : getOnlyNumber(value).slice(0, -1);
  };
  return (
    <Grid container={true}>
      <OutlinedInput
        fullWidth={true}
        type={'string'}
        value={getFormatCurrency(value)}
        onKeyPress={(event) => {
          if (!fullNumberRangeRegExp.test(event.key)) {
            event.preventDefault();
          }
        }}
        sx={(theme: Theme) => ({
          borderRadius: '29px',
          backgroundColor: selected ? theme.colors.primary : 'transparent',
          [theme.breakpoints.down('md')]: {
            minWidth: '100px',
          },
          '& input + fieldset': {
            borderColor: selected
              ? theme.colors.primary
              : theme.colors.borderColor,
            borderWidth: selected ? '2px' : '1px',
          },
          '& input': {
            padding: '12px 17px',
            textAlign: 'center',
            fontSize: '24px',
            lineHeight: '24px',
            height: '24px',
            [theme.breakpoints.down('md')]: {
              padding: '6px 14px',
              fontSize: '20px',
              lineHeight: '20px',
              height: '26px',
            },
            [theme.breakpoints.down('sm')]: {
              padding: '2px 12px',
              textAlign: 'center',
              fontSize: '18px',
              lineHeight: '28px',
              height: '28px',
            },
          },
        })}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={'Другая сумма'}
      />
    </Grid>
  );
};
