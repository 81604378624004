import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SuccessCheckIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 80 80'}
    sx={{ width: '80px', height: '80px' }}
    {...props}>
    <path
      opacity={'0.14'}
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M40 0C17.92 0 0 17.92 0 40C0 62.08 17.92 80 40 80C62.08 80 80 62.08 80 40C80 17.92 62.08 0 40 0Z'
      }
      fill={'#ffaf37'}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M35.4286 51L25.6002 41.0962C24.7145 40.2036 24.7145 38.7637 25.6002 37.8712C26.4947 36.9698 27.952 36.9688 28.8478 37.8689L35.4286 44.4818L51.1419 28.6478C52.042 27.7408 53.5096 27.7433 54.4066 28.6536C55.2895 29.5496 55.2869 30.9892 54.4009 31.882L35.4286 51Z'
      }
      fill={'#ffaf37'}
    />
  </SvgIcon>
);
