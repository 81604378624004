import { Box, Grid } from '@mui/material';
import { FilledLogoIcon } from '@/app/icons/FilledLogoIcon';
import { Logo } from '@/app/icons/Logo';
import { NavigationDrawer } from '@/components/common/navigation/NavigationDrawer';
import { ROUTE_TEMPLES } from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  hideMenu?: boolean;
  isMainPage: boolean;
}

export const MobileHeader: FC<Props> = (props: Props) => {
  const { hideMenu, isMainPage } = props;
  return (
    <Grid
      id={'top'}
      container={true}
      alignItems={'center'}
      justifyContent={'space-between'}
      wrap={'nowrap'}
      sx={{
        color: isMainPage ? 'white' : 'black',
        minHeight: '67px',
      }}>
      <Grid item={true} display={'flex'} zIndex={10} pb={1}>
        {isMainPage ? (
          <Box position={'relative'} mt={'-30px'}>
            <Box position={'absolute'} top={0} left={0}>
              <RouteLink to={ROUTE_TEMPLES}>
                <FilledLogoIcon
                  sx={{
                    width: (theme: Theme) => theme.spacing(9.5),
                    height: (theme: Theme) => theme.spacing(9.5),
                  }}
                />
              </RouteLink>
            </Box>
          </Box>
        ) : (
          <Logo
            style={{ color: 'black', backgroundColor: 'white' }}
            sx={{
              width: (theme: Theme) => theme.spacing(7.875),
              height: (theme: Theme) => theme.spacing(7.375),
            }}
          />
        )}
      </Grid>
      <Grid item={true}>{!hideMenu && <NavigationDrawer />}</Grid>
    </Grid>
  );
};
