import { ControlledContainer } from '@/components/main/swiper/ControlledContainer';
import { Controller, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { FC } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
  controlledSwiper: any;
}

export const ControllSwiper: FC<Props> = (props: Props) => {
  const { controlledSwiper } = props;

  return (
    <Swiper
      loop={true}
      controller={{ control: controlledSwiper }}
      pagination={{ clickable: true }}
      navigation={true}
      modules={[Navigation, Pagination, Controller]}
      className={'mainSwiper'}>
      <SwiperSlide>
        <ControlledContainer />
      </SwiperSlide>
      <SwiperSlide>
        <ControlledContainer />
      </SwiperSlide>
      <SwiperSlide>
        <ControlledContainer />
      </SwiperSlide>
      <SwiperSlide>
        <ControlledContainer />
      </SwiperSlide>
    </Swiper>
  );
};
