import { AttachFileDTO } from '@/types/AttachFileDTO';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, useState } from 'react';

interface Props {
  image: AttachFileDTO;
  handleSelected: () => void;
  selected: boolean;
  isMobile?: boolean;
}

export const TempleSecondaryImage: FC<Props> = (props: Props) => {
  const { image, selected, handleSelected, isMobile } = props;
  const [hover, setHover] = useState<boolean>(false);
  return (
    <Box
      onClick={handleSelected}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={(theme: Theme) => ({
        position: 'relative',
        height: isMobile ? '48px' : '150px',
        width: isMobile ? '48px' : '150px',
        overflow: 'hidden',
        borderRadius: '6px',
        border: '2px solid',
        borderColor: selected ? theme.colors.primary : 'white',
        background: 'white',
        cursor: 'pointer',
      })}>
      <img
        src={`data:image/jpeg;base64,${image.content}`}
        alt={'temple-image'}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: selected || hover ? 0 : 0.5,
        }}
      />
    </Box>
  );
};
