import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const OnlinePaymentIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 128 80'}
    sx={{ width: '128px', height: '80px' }}
    {...props}>
    <g clipPath={'url(#clip0_1178_26950)'}>
      <path
        d={
          'M127.964 25.0996C127.95 25.369 127.747 25.6504 127.361 25.8736L122.851 28.5112L120.283 30.0089L51.8237 70.0411L49.4742 71.4222L44.964 74.0598C44.863 74.1168 44.7583 74.1672 44.6507 74.2105L44.6788 79.8013C44.0678 80.0448 43.3914 80.0682 42.7651 79.8677L42.737 74.2809C42.6559 74.2519 42.5773 74.2163 42.502 74.1744L0.427729 50.1285C0.285499 50.054 0.165605 49.943 0.0803238 49.8069C0.0296635 49.7209 0.0020001 49.6232 0 49.5234L0.0100382 51.186V51.4011L0.0341378 55.1323C0.038683 55.2301 0.0669387 55.3253 0.116469 55.4097C0.199794 55.5423 0.316029 55.6511 0.453836 55.7253L8.96628 60.5803L9.15304 60.6869L20.6516 67.2547L20.8383 67.3613L32.3369 73.9311L32.5216 74.0377L42.5361 79.7591C42.9969 79.9798 43.5153 80.0501 44.0181 79.9602L44.2029 79.928C44.4813 79.8734 44.7492 79.7743 44.9961 79.6345L47.195 78.3479L47.3797 78.2413L55.6352 73.4165L55.8199 73.3099L58.81 71.5609L58.9948 71.4523L67.2522 66.6275L67.5996 66.4265L70.423 64.776L70.6077 64.6674L78.6844 59.9451L79.0318 59.744H79.0499L82.04 57.997L82.2248 57.8885L90.4822 53.0636L90.6669 52.9551L93.657 51.2081L93.8418 51.0995L102.099 46.2747L102.284 46.1681L105.274 44.4191L105.459 44.3106L113.716 39.4857L113.901 39.3771L116.891 37.6301L117.076 37.5216L125.333 32.6967L125.518 32.5882L127.205 31.6011L127.394 31.4925C127.448 31.4604 127.498 31.4262 127.546 31.392C127.671 31.3175 127.777 31.2135 127.853 31.0892C127.929 30.9649 127.974 30.8239 127.984 30.6783V30.5416V30.3285L127.952 25.1237'
        }
        fill={'#ffe49e'}
      />
      <path
        d={
          'M127.884 24.7811C127.942 24.88 127.97 24.994 127.964 25.1088C127.95 25.3782 127.747 25.6597 127.361 25.8828L122.851 28.5204L78.4916 3.1618L82.8533 0.610658C83.034 0.506119 83.2128 0.391528 83.3895 0.272917C83.647 0.103188 83.9467 0.00858776 84.2549 -0.000288814C84.5631 -0.00916539 84.8677 0.0680326 85.1345 0.222658L108.83 13.7664L113.6 16.4904L113.786 16.597L125.293 23.1728L125.478 23.2794L127.536 24.4534C127.678 24.5287 127.798 24.6404 127.884 24.7771V24.7811ZM29.9532 31.542L5.10868 46.0688L49.4742 71.4213L44.964 74.0589C44.863 74.116 44.7583 74.1663 44.6507 74.2097C44.04 74.4533 43.3639 74.4781 42.737 74.28C42.6559 74.251 42.5773 74.2154 42.502 74.1735L0.427729 50.1277C0.285499 50.0532 0.165605 49.9422 0.0803238 49.806C0.0296635 49.72 0.0020001 49.6224 0 49.5226C0 49.2411 0.200813 48.9395 0.602438 48.7043L8.88797 43.8614L9.07272 43.7528L9.59684 43.4453L9.78158 43.3367L20.505 37.0684L20.6897 36.9599L21.2138 36.6543L21.3986 36.5457L29.9572 31.542'
        }
        fill={'#fff6e0'}
      />
      <path
        d={
          'M78.4924 3.16211L122.858 28.5107L49.4749 71.4156L5.10938 46.0691L78.4924 3.16211Z'
        }
        fill={'#ffc737'}
      />
      <path
        d={
          'M99.7585 11.4856L107.791 16.0612C108.026 16.1959 108.161 16.3466 108.014 16.5316C107.959 16.6029 107.891 16.663 107.813 16.7085C107.629 16.8123 107.423 16.8715 107.211 16.8812C107 16.891 106.789 16.8511 106.596 16.7648L98.4854 12.1309L98.4191 12.0867C98.3528 11.8294 98.3729 11.7691 98.7042 11.5741L98.8147 11.552C99.1761 11.3409 99.4493 11.3067 99.7625 11.4856H99.7585Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M109.089 39.4089V39.5134C109.068 39.7423 108.994 39.963 108.872 40.1578C108.751 40.3527 108.585 40.5162 108.388 40.6352L103.617 43.4255C103.228 43.6527 102.908 43.52 102.906 43.13V43.0235C102.928 42.7946 103.003 42.574 103.125 42.3792C103.247 42.1845 103.413 42.0209 103.609 41.9017L108.38 39.1133C108.77 38.8842 109.087 39.0168 109.089 39.4089Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M116.372 35.151V35.2556C116.351 35.4846 116.276 35.7054 116.154 35.9002C116.032 36.095 115.866 36.2585 115.67 36.3774L110.898 39.1677C110.509 39.3949 110.191 39.2622 110.189 38.8722V38.7657C110.211 38.5368 110.285 38.3162 110.407 38.1213C110.528 37.9265 110.694 37.763 110.89 37.6439L115.664 34.8555C116.053 34.6263 116.37 34.759 116.372 35.151Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M115.086 44.4553L114.303 44.3528C114.216 44.2888 114.125 44.2297 114.032 44.1759L53.5469 9.2199C51.9846 8.31725 49.2556 8.42983 47.4503 9.4732L12.1795 29.8662C11.7653 30.0947 11.3992 30.4012 11.1012 30.7689L10.6875 30.6482V31.8685C10.6875 32.4375 11.0369 32.9823 11.7398 33.3884L72.2225 68.3464C73.7848 69.249 76.5139 69.1365 78.3192 68.0951L113.59 47.7001C114.594 47.1191 115.104 46.3692 115.08 45.6636V44.4574L115.086 44.4553Z'
        }
        fill={'#ffb905'}
      />
      <path
        d={
          'M72.2228 67.1218L11.734 32.1638C10.1716 31.2612 10.3725 29.683 12.1798 28.6417L47.4626 8.24664C49.2699 7.20327 51.9969 7.09069 53.5592 7.99333L114.032 42.9514C115.594 43.854 115.399 45.4321 113.594 46.4755L78.3114 66.8685C76.5041 67.9119 73.7771 68.0245 72.2147 67.1218H72.2228Z'
        }
        fill={'#fff6e0'}
      />
      <path
        d={
          'M81.9736 64.7516L15.834 26.5247L22.8544 22.4658L88.9941 60.6927L81.9736 64.7516Z'
        }
        fill={'#e6a500'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_1178_26950'}>
        <rect width={'128'} height={'80'} fill={'white'} />
      </clipPath>
    </defs>
  </SvgIcon>
);
